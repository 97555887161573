<template>
	<div class="Y_popover" id='Y_popover' ref='Y_popover' :class="mClass" :style="diyStyle">
		<div v-if="datas.open">
			<div class="triangle" :class="arrow"></div>
			<div class="p-body">
				<transition name="el-fade-in-linear">
					<slot></slot>
			 	</transition>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: {
			datas:{
				type:Object,
				default:()=>{
					return {
						open:false,
						x:0,
						y:0,
					}
					
				}
			},
			mClass:{
				type:String,
				default:'',
			}
		},
		watch: {
		 	datas: {
		      	deep: true, // 深度监听
		      	handler(val,oval) {
		      		this.getWidth();
		        	
		      	}
		   	},
		},
		data() {
			return {
				arrow:'left',//箭头方向
				maxWidth:1920,//屏幕宽度
				ypWidth:400,//弹框宽度
				diyStyle:{
					position: 'fixed',
					left:-100,
    				top: -100,
				},
				
			};
		},
		created(){
			APP.$on('countPoverXy',()=>{
				//开始监听
				this.getWidth();
			});
		},
		mounted() {
			this.getWidth();
			this.diyStyle={
        		position: 'fixed',
				left:this.datas.x,
				top: this.datas.y,
        	}
		},
		methods: {
			
			getWidth(){
				this.$nextTick(()=>{
					
					this.maxWidth=document.body.clientWidth;
					
					let divEl=this.$refs['Y_popover'];
					if(!divEl) return;
		      		let containerRect = divEl.getBoundingClientRect();
		      		let elWidth=document.getElementById("Y_popover").offsetWidth;
		      		if(containerRect){
		      			this.ypWidth=containerRect.width;
		      		}
		      		let fixX=this.datas.x;
		      		if(fixX>(this.maxWidth-this.ypWidth)){
		      			fixX=this.datas.x-this.ypWidth-40;
		      			this.arrow='right';
		      		}else{
		      			this.arrow='left';
		      		}
		      		this.diyStyle={
		        		position: 'fixed',
						left:fixX+'px',
	    				top: this.datas.y+'px',
		        	}
		        	if(this.datas.open){
		        		setTimeout(()=>{
		        			this.listenClick();
		        		},1000)
		        	}else{
		        		document.onclick=null;
		        	}
//					console.log('containerRect',this.maxWidth,this.ypWidth,elWidth);
						
					
				})
				
			},
			listenClick(){
				let that=this;
				let box=document.getElementById("Y_popover");
				document.onclick=function(e){
					if(that.datas.open){
						if(!box.contains(e.target)){
							that.$emit('pClose')
						}
					}
					
					
				}
				
				
			},
			
			setFixedXy(){
				return {
					position: 'fixed',
					left:this.datas.x,
    				top: this.datas.y,
				}
				
			},
			
			
			
		},
		destroyed() {
			document.onclick=null
		},
	};
</script>
<style lang="scss">
	.Y_popover{
    	z-index: 999999;
		padding: 0 8px;
		width: fit-content;
		position: relative;
		.p-body{
			box-shadow: 3px 0px 4px 1px #eee;
			width: fit-content;
			background: white;
			padding: 12px;
			border-radius: 5px;
		}
		.triangle,.left {
			position: absolute;
			top: 10px;
			left: -5px;
		    width: 0;
		    height: 0;
		    border: 6px solid;
		    border-color: transparent blue transparent transparent;
		}
		.triangle.right{
			position: absolute;
			top: 10px;
		    left: 98%;
		    width: 0;
		    height: 0;
		    border: 6px solid;
	     	border-color: transparent transparent transparent blue;
		}
		
	}
	
</style>