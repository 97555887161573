<template>
	<div class="Y-add_shifts">
	  	<div class="tabs_warp" v-if="showTbas">
	  		<div class="it_tap" v-for="(it,dex) in tabsArr" :key='dex' :class="{'actived':activeTabs==it.val}">
	  			<div class="" @click='changeTbas(it)'>{{ it.name }}</div>
	  		</div>
	  	</div>	
	  	<div class="list_warp" v-if='activeTabs=="1"'>
	  		<div v-if="shiftsArrs.length>0">
	  			<div class="it_sts" v-for="it in shiftsArrs" :key='it.id' v-if='!it.beforeNow'   @click.stop="checkedDatas(it,'shifts')" :style="getDiyStyle(it)" >
	  				<div class="it_name txNowrap">{{it.shiftName}} ( {{it.shiftShortName}} )</div>
	  				<div class="it_time txNowrap">
						<span v-for="(_tt,dex2) in it.workTimeArray">
							<span v-if="dex2>0">/</span>
							<span v-if="_tt.startTime">{{_tt.startTime.time}}</span>-
							<span v-if="_tt.endTime">{{_tt.endTime.time}}</span>
						</span>
	  					
	  				</div>
	  			</div>
  			</div>
			<div  class="no-data" v-if='tdAllBefore||shiftsArrs.length<1'>
				暂无可选班次
			</div>
  		</div>
		<div class="kq_list" v-if='activeTabs=="2"'>
			<div v-if="attendanceArrs.length>0">
				<div class="k_it" v-for="it in attendanceArrs" :key='it.id' @click.stop="checkedDatas(it,'attend')">
					<span class="k_it_icon">{{it.attendanceSign}}</span>
					<span class="k_it_name txNowrap" :title="it.attendanceName">{{it.attendanceName}}</span>
				</div>
			</div>
			<div  class="no-data" v-else>
				 暂无数据
			</div>
			
			
		</div>
		
	</div>
</template>
<script>
	export default {
		props: {
			empId: {
				type: String|Number,
				default: '1',
			},
			showTbas: {
				type: Boolean,
				default: true,
			},
			shiftDatas:{
				type: Object,
				default: ()=>{
					return {
						date: "",
						shiftList:null,
						type: 1,
					}
				}
			},
			
		},
		components: {},
		data() {
			return {
				tabsArr:[
					{name:'添加班次',val:'1'},
					{name:'修改考勤',val:'2'},
				],
				todayStr:'',
				activeTabs:'1',
				shiftsArrs:[
//					{
//						id:9,
//						shiftName: 'Y班',
//						shiftShortName: "Y",
//						isDefault: false,
//						shiftColor: '#19d318',
//						shiftDuration: '12小时',
//						workTimeArray:[
//							{startTime: {time: "21:00", workDay: "today"}, endTime: {time: "23:59", workDay: "today"}}
//						],
//					}, 
					
				
				],
				attendanceArrs:[],
				tdAllBefore:false,
			}
		},
		
		computed: {},
		watch:{
			shiftDatas: {
		      	deep: true, // 深度监听
		      	handler(val,oval) {
		      		this.tdAllBefore=false;
		      		this.activeTabs='1';
		      		this.getShiftsByEmpId(this.empId);
		      		
		      	}
		   	},
			
			
		},
		created() {
			this.todayStr=this.common.getTimeDay(new Date());
			console.log('empId',this.empId)
			this.getShiftsByEmpId(this.empId);
			this.getAttendanceArrs();
		},
		mounted() {
			APP.$emit('countPoverXy');
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			changeTbas(it){
				this.activeTabs=it.val;
			},
			checkedDatas(data,type){
				//选中班次或者考勤 传递父级  修改数据 type：shifts班次   attend考勤
				data.sendType=type;
				data.waitUp='1';
				let sftArrs=JSON.parse(JSON.stringify(this.shiftDatas.shiftList))
				if(!sftArrs||sftArrs.length<1){
					//一个班次都没有的时候 不校验 直接添加
					this.$emit('setDatas',data);
					return;
				}
				console.log('选中班次',JSON.parse(JSON.stringify(data)));
				//开始校验班次规则
				let isAllow=true;
//				
//				
//				for(let it of sftArrs){
//					if(data.id==it.id){
//						isAllow=false;
//						this.$message({type:'warning',message:'存在相同班次'})
//						return;
//					}
//				}
				let addAllow=true;
//				if(type=='shifts'){
//					addAllow=this.common.diffTimeAllow(sftArrs,[data])
//				}
				if(addAllow){
					this.$emit('setDatas',data);
				}else{
					
					this.$message({type:'warning',message:'班次时间冲突~'})
					
				}
			},
			
			async getShiftsByEmpId(id){
				//通过员工id 获取排班数据
				let params = { empId:id };
				this.shiftsArrs=[];
	  			let res = await this.ApiHttp('/scheduling/arrangeShift/findShiftListByEmpId',params);
	  			if(res){
	  				
	  				let dataDay=this.common.getTimeDay(new Date(this.shiftDatas.date));
	  				if(this.todayStr==dataDay){
	  					//如果是同一天的话
	  					let nowTime=new Date().getTime();
	  					let beforeNum=0;//判断是不是全部都是今天过去了的班次
	  					
	  					res.map(it=>{
	  						it.beforeNow=false;//判断班次是否在当天之前  如果是的话 不允许添加
		  					if(it.workTimeArray){
		  						for(let _ws of it.workTimeArray){
		  							let t1=new Date(this.todayStr+' '+_ws.startTime.time).getTime();
		  							let t2=new Date(this.todayStr+' '+_ws.endTime.time).getTime();
		  							if(_ws.startTime.workDay=='nextDay'||_ws.endTime.workDay=='nextDay'){
		  								//处理次日的时候
		  								var dd = new Date();
        								dd.setDate(dd.getDate() + 1);
        								let nexDayStr=this.common.getTimeDay(dd);
        								if(_ws.startTime.workDay=='nextDay'){
        									t1=new Date(nexDayStr+' '+_ws.startTime.time).getTime();
        								}
        								if(_ws.endTime.workDay=='nextDay'){
        									t2=new Date(nexDayStr+' '+_ws.endTime.time).getTime();
        								}
		  							}
		  							if(t2>t1){
		  								//确保不存在次日的时候
		  								if(t1<nowTime||t2<nowTime){
			  								it.beforeNow=true;
			  								beforeNum++
			  								break;
			  							}
		  							}
		  						}
		  					}
		  				})
	  					if(beforeNum==res.length){
	  						this.tdAllBefore=true;
	  					}else{
	  						this.tdAllBefore=false;
	  					}
	  				}
	  				this.shiftsArrs=res;
	  				console.log('获取排班数据1',this.shiftsArrs)
	  			}
			},
			async getAttendanceArrs(){
				//获取考勤符号数据
//				let s_data=sessionStorage.getItem('attendDatas');
//				if(s_data){
//					this.attendanceArrs=JSON.parse(s_data);
//					return;
//				}
//				
	  			let res = await this.ApiHttp('/scheduling/attendance/list');
	  			if(res){
	  				this.attendanceArrs=res;
//	  				console.log('获取考勤符号数据',res)
//	  				sessionStorage.setItem('attendDatas',JSON.stringify(res))
	  			}
			},
			closeEvsPopover(){
				document.elementFromPoint(50, 60).click();
			},
			getDiyStyle(sfs) {
				let color=sfs.shiftColor;//sfs.shiftColor;
				let bg = this.common.hexToRgba(color);
				return {
					'background': bg,
					'border-left': '3px solid ' + color
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.Y-add_shifts{
	
	width: 300px;
	padding: 0 20px 10px 20px;
	
}
.tabs_warp{
	display: flex;
	.it_tap{
		padding: 10px;
		line-height: 24px;
		cursor: pointer;
		width: 50%;
		text-align: center;
		font-size: 14px;
	}
	.it_tap:hover{
		color: #409EFF;
	}
	.actived.it_tap{
		color: #1A4CEC;
		border-bottom: 2px solid #1A4CEC;
	}
}
.list_warp{
	max-height: 248px;
	overflow-y: auto;
	.it_sts{
		font-size: 12px;
		padding: 5px 0;
		padding-left: 12px;
		height: 40px;
		border-left: 2px solid;
		border-color:#1A4CEC;
		cursor: pointer;
		background: #DDE4FC;
		border-radius: 2px;
		margin: 10px 0;
		.it_name{
			color: #2F3941;
		}
	}
	.it_time{
		line-height: 20px;
		padding: 2px 0;
	}
}
.kq_list{
	max-height: 248px;
	overflow-y: auto;
	.k_it{
		font-size: 12px;
		padding-left: 12px;
		height: 40px;
		line-height: 40px;
		border-left: 2px solid;
		border-color:#1A4CEC;
		cursor: pointer;
		background: #DDE4FC;
		border-radius: 2px;
		margin: 10px 0;
		.k_it_icon{
			margin-right: 10px;
		}
		.k_it_name{
			vertical-align: middle;
			color: #2F3941;
			display: inline-block;
			max-width: 80%;
		}
	}
	
}
.no-data{
	text-align: center;
	margin-top: 24px;
}
</style>