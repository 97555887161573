var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Y-change_shifts" }, [
    _c("div", { staticClass: "n_title" }, [_vm._v(" 更换班次")]),
    _c("div", { staticClass: "tips" }, [
      !_vm.activeShifts
        ? _c("span", [_vm._v("请选择要更换的班次")])
        : _c("span", [
            _vm._v("请选择替换 [ "),
            _c(
              "span",
              {
                style: {
                  color: _vm.activeShifts.shiftColor
                    ? _vm.activeShifts.shiftColor
                    : "",
                },
              },
              [_vm._v(_vm._s(_vm.activeShifts.shiftName))]
            ),
            _vm._v(" ] 的班次"),
          ]),
    ]),
    _vm.activeShifts
      ? _c(
          "div",
          [
            _c("AddScheduling", {
              attrs: {
                showTbas: false,
                empId: _vm.empId,
                shiftDatas: _vm.datas,
              },
              on: { setDatas: _vm.setChageDatas },
            }),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "list_warp" },
          [
            _vm._l(_vm.shiftListArr, function (it, dex) {
              return !it.beforeNow
                ? _c(
                    "div",
                    {
                      key: dex,
                      staticClass: "it_sts",
                      style: _vm.getDiyStyle(it),
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.checkedToChange(it)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "it_name txNowrap" }, [
                        _vm._v(
                          _vm._s(it.shiftName) +
                            " ( " +
                            _vm._s(it.shiftShortName) +
                            " )"
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "it_time txNowrap" },
                        _vm._l(it.workTimeArray, function (_tt, dex2) {
                          return _c("span", [
                            dex2 > 0 ? _c("span", [_vm._v("/")]) : _vm._e(),
                            _tt.startTime
                              ? _c("span", [_vm._v(_vm._s(_tt.startTime.time))])
                              : _vm._e(),
                            _vm._v("-\n\t\t\t\t\t\t"),
                            _tt.endTime
                              ? _c("span", [_vm._v(_vm._s(_tt.endTime.time))])
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e()
            }),
            _vm.tdAllBefore || _vm.shiftListArr.length < 1
              ? _c("div", { staticClass: "no-data" }, [
                  _vm._v("\n\t\t\t\t暂无可选班次\n\t\t\t"),
                ])
              : _vm._e(),
          ],
          2
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }