var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Y-del_shifts" }, [
    _c("div", { staticClass: "n_title" }, [_vm._v(" 删除班次")]),
    _c(
      "div",
      { staticClass: "list_warp" },
      _vm._l(_vm.shiftListArr, function (it, dex) {
        return _c("div", { key: dex, staticClass: "itWarp" }, [
          _c(
            "div",
            { staticClass: "lineDiv checkWarp" },
            [
              _c("el-checkbox", {
                model: {
                  value: it.checked,
                  callback: function ($$v) {
                    _vm.$set(it, "checked", $$v)
                  },
                  expression: "it.checked",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "it_sts lineDiv",
              style: _vm.getDiyStyle(it),
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  it.checked = !it.checked
                },
              },
            },
            [
              _c("div", [
                _c("div", { staticClass: "it_name txNowrap" }, [
                  _vm._v(
                    _vm._s(it.shiftName) +
                      " ( " +
                      _vm._s(it.shiftShortName) +
                      " )"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "it_time txNowrap" },
                  _vm._l(it.workTimeArray, function (_tt, dex2) {
                    return _c("span", [
                      dex2 > 0 ? _c("span", [_vm._v("/")]) : _vm._e(),
                      _tt.startTime && _tt.startTime.time
                        ? _c("span", [_vm._v(_vm._s(_tt.startTime.time))])
                        : _vm._e(),
                      _vm._v("-\n\t\t\t\t\t\t\t\t"),
                      _tt.endTime && _tt.endTime.time
                        ? _c("span", [_vm._v(_vm._s(_tt.endTime.time))])
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          ),
        ])
      }),
      0
    ),
    _vm.activeTabs == "2"
      ? _c(
          "div",
          { staticClass: "kq_list" },
          _vm._l(5, function (it) {
            return _c("div", { staticClass: "k_it" }, [
              _c("span", { staticClass: "k_it_icon" }, [_vm._v("★")]),
              _c("span", { staticClass: "k_it_name" }, [_vm._v("法定节假日")]),
            ])
          }),
          0
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "btmBtns" },
      [
        _c("el-button", { on: { click: _vm.closeEvsPopover } }, [
          _vm._v("取消"),
        ]),
        _c(
          "el-button",
          { attrs: { type: "primary" }, on: { click: _vm.saveDatas } },
          [_vm._v("确定")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }