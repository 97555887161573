<template>
	<!--待发布-->
	<div class="st-warit">
		<el-drawer :size="620" title="待发布排班表" :visible.sync="$attrs.isOpen" :wrapperClosable="false" direction="rtl" :before-close="handleClose">
			<div class="attendanceWarp" v-loading="isLoading">
				<div v-for="(group,dex) in shiftsArrs" :key='dex' class="g_item">
					<div class="g_name">{{group.attendanceName}}</div>
					<div v-for="(it,dex) in group.timeArrs">
						<div class="it_day"><span class="colorDot"></span> {{it.date}} </div>
						<div class="e_itWarp">
							<div v-for="(its,dx2) in it.shiftlist" :key='dx2' >
								<div v-if="dx2<it.showNum" class="e_it">
									<div class="e_name inline">
										<div class="_head inline">
											<span v-if="its.empName">{{its.empName.substring(its.empName.length-1)}}</span>
										</div>
										<div class="_job inline">
											<div class="j_name txNowrap">{{its.empName}}</div>
											<div class="j_detail txNowrap">{{its.dutyName||'-'}}</div>
										</div>
									</div>
									<div v-for="(sfts,dx3) in its.shiftArrs" :key='dx3' class="s_item inline" :style="common.shiftStyle(sfts)">
										<div class="delStyle" v-if='sfts.isDelete'>已删除</div>
										<div class="s_name txNowrap" :title="sfts.shiftName">{{sfts.shiftName}}</div>
										<div class="s_duration txNowrap">{{sfts.shiftDuration}}</div>
										<div class="s_worktime txNowrap">
											<span v-for="(_tt,_t) in sfts.workTimeArray" :key='_t'>
												<span v-if='_t>0'>/</span>
												<span v-if="_tt.startTime">{{_tt.startTime.time}}</span>
												-
												<span v-if="_tt.endTime">{{_tt.endTime.time}}</span>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div style="font-size: 14px;" v-if="it.shiftlist.length>2">
								<span v-if="it.shiftlist.length-it.showNum>0">已隐藏{{it.shiftlist.length-it.showNum}}项 </span>
								<el-button type="text" @click='showShitsNum(it)'>
									 <span v-if="it.showNum>2"> 收起 <i class="el-icon-arrow-up"></i></span>
									 <span v-else>展开更多 <i class="el-icon-arrow-down"></i></span>
								</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="btmBtn">
				<div class="w_check">
					<!--<el-checkbox v-model="isNotice">通过微信服务号通知</el-checkbox>-->
				</div>
				<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
				<el-button type="primary" :disabled='isHttping' @click='getDatas'>确定</el-button>
			</div>
		</el-drawer>

	</div>
</template>
<script>
	export default {
		props: {
			datas: {
				type: Object,
				default: () => {
					return {
						"empId": '',
						"year": '2023',
						"month": '2',
						'empName': '',
					}
				},
			},
			seachData:{
				type: Object,
				default: () => {
					return {
						yNum:'',
						mNum:'',
						attendanceGroupId: '',
					}
				},
			},
		},
		components: {},
		data() {
			return {
				isNotice:true,
				shiftsArrs: [],
				staticsObj: {
					'测试2': ' 1'
				},
				draftShiftDatas:null,
				isHttping:false,
				isLoading:false,
			}
		},

		computed: {},
		watch: {
			datas: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.initDatasById();

				}
			},

		},
		created() {
			this.initHtml();
		},
		mounted() {},

		methods: {
			async getDatas() {
				//点击发布
				let params={
					empIdSet:this.draftShiftDatas.arrangeShiftEmployeeList.map(it=>{
						return it.empId
					}),
					isNotice:this.isNotice,
				}
				this.isHttping=true;
				let res = await this.ApiHttp('/scheduling/arrangeShift/publishArrangeShift',params,'post');
				console.log('-----',res)
				if(res.status){
					this.isHttping=false;
					this.$message({type: "success",message: '操作成功'});
					this.handleClose();
					this.$emit('rest')
				}
			},
			handleClose(){
				console.log('14545')
				this.$emit('close')
			},
			showShitsNum(row){
				if(row.showNum<3){
					row.showNum=row.shiftlist.length;
				}else{
					row.showNum=2;
				}
			},
			async initDatasByStatus(status='0'){
				//获取发布的数据1是发布  0是草稿
				let params={
					'status':status,//1是发布  0是草稿
				    "year":this.seachData.yNum,
				    "month":this.seachData.mNum,
				    "empName":this.seachData.empName,
				    'deptId':this.seachData.deptId,
				   	'companyId':this.seachData.companyId,
                    'attendanceGroupId': this.seachData.attendanceGroupId,
                    "defaultAttendanceGroup":this.seachData.defaultAttendanceGroup,
				}
				let res = await this.ApiHttp('/scheduling/arrangeShift/getArrangeShiftByNaturalMonth',params,'post');
				return res;
				console.log('数据',this.tbHeads,JSON.parse(JSON.stringify(res)))
				
			},
			initDraftDatas(){
				//获取草稿的数据
				
				
			},
			async initHtml() {
				this.isLoading=true;
				let draftData=await this.initDatasByStatus();//草稿数据
				if(draftData){
					this.draftShiftDatas=JSON.parse(JSON.stringify(draftData))
				}
				let releaseData=await this.initDatasByStatus('1');//发布数据
				let draftArr=draftData.arrangeShiftEmployeeList;
				let releaseArr=releaseData.arrangeShiftEmployeeList;
//				console.log(111111111,draftArr,releaseArr)
				if(!draftArr||!releaseArr){
					this.isLoading=false;
					return;
				}
				let resArr=[];
				draftArr.map((it,i)=>{
					let r_it=releaseArr[i];//发布的对应数据
					
					it.detailList.map((_d,dex)=>{
						let r_d=r_it.detailList[dex];
						if(!_d.shiftList) _d.shiftList=[];
						if(!r_d.shiftList) r_d.shiftList=[];
//						let shift1=JSON.stringify(_d.shiftList);
//						let shift2=JSON.stringify(r_it.shiftList);
						if(_d.yellowDot){
							//草稿与发布的排班信息不一样   代表数据发生过更改
							let filterArr=[];
							let idArr2=r_d.shiftList.map(_it1=>{
								return _it1.id;
							})
							
							let idArr1=[]
							_d.shiftList.map((_s,sort)=>{
								//与已发布进行比对，不存在于已发布 则代表是新增变动的班次，
								let index=idArr2.indexOf(_s.id);
								if(index<0){
									filterArr.push(_s)
								}else{
									if(JSON.stringify(_s)!==JSON.stringify(r_d.shiftList[sort])){
										//这种情况是 班次没发生变更   但是班次信息发生更改 例如A1 改成了A2  同样属于变化
										filterArr.push(_s)
									}
								}
								
								idArr1.push(_s.id)
							})
							let ArrShits=_d.shiftList;
							r_d.shiftList.map((_s2,sort2)=>{
								//通过判断草稿里面的班次里面 是否还有发布的数据 没有的话 代表已经呗删除
								let index=idArr1.indexOf(_s2.id);
								if(index<0){
									_s2.isDelete=true;
									filterArr.push(_s2)
								}
								
							})
							let len =filterArr.length;
							if(len>0){
								let obj={
									...it,
									day:_d.date,
									shiftArrs:filterArr,
//									empId: it.empId,
//									empName: it.empName,
//									attendanceId:it.attendanceId,
//									attendanceName: it.attendanceName,
								}
								resArr.push(obj);
							}
						}
						
					})
				
				})
				
				//按考勤组组合 =>
				let groupObj={};
				resArr.map((_dd,dex)=>{
					let key1=_dd.attendanceId;
					if(!groupObj[key1]){
						groupObj[key1]={
							attendanceId: _dd.attendanceId,
							attendanceName: _dd.attendanceName,
							timeObj:{},
						}
					}
					let tObj=groupObj[key1].timeObj
					if(!tObj[_dd.day]){
						tObj[_dd.day]=[]
					}
					tObj[_dd.day].push(_dd)
					let Arrs1=[];
					
					for(let k1 in tObj){
						let len =tObj[k1].length;
						Arrs1.push({
							date:k1,
							shiftlist:tObj[k1],
							showNum:len>2?2:len
							
						})
					}
					Arrs1=this.common.quickSort(Arrs1, 'date');
					groupObj[key1].timeArrs=Arrs1;
					
				})
				let groupArrs=[];
				for(let keys in groupObj){
					groupArrs.push(groupObj[keys])
					
				}
				this.isLoading=false;
				this.shiftsArrs=this.common.quickSort(groupArrs, 'attendanceId');;
				console.log('22222222',JSON.parse(JSON.stringify(groupObj)))
				console.log('33333333',JSON.parse(JSON.stringify(groupArrs)))
				
			},
			closeMypup() {
				this.$emit('close');

			},

		},
		beforeDestroy() {},

	}
</script>
<style lang="scss" >
	.st-warit .el-drawer__header{
		color: #333333;
		font-size: 18px;
		span{
			font-weight: bold;
		}
	}
</style>
<style lang="scss" scoped>
	.btmBtn {
		border-top: 1px solid #EEEEEE;
		text-align: right;
		position: relative;
		padding: 16px;
		.w_check{
			position: absolute;
			left: 16px;
			top: 24px;
		}
	}
	.attendanceWarp{
		height: 86vh;
		overflow-y: auto;
		border-top: 1px solid #EEEEEE;
		.g_item{
			
			padding:0 12px;
			padding-left:20px;
			.g_name{
				color: #333333;
				font-size: 16px;
				font-weight: bold;
				padding: 12px 0;
				
				
			}
			.it_day{
				margin-top: 8px;
				/*padding: 6px 0;*/
				.colorDot{
					display: inline-block;
					width: 9px;
					height: 9px;
					background: #CCCCCC;
					border-radius: 50%;
					color: #999999;
					
				}
			}
			.e_itWarp{
				
				.inline{
					vertical-align: text-top;
					display: inline-block;
					font-size: 14px;
				}
				.e_it{
					padding: 8px;
					margin: 0;
					font-size: 0;
					
					.e_name{
						width: 148px;
						padding-top: 16px !important;
						background: #E0E0E0;
						
						._head{
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background: #1A4CEC;
							margin-right: 8px;
							color: white;
							line-height: 40px;
							text-align: center;
						}
						._job{
							line-height: 20px;
							.j_detail{
								font-size: 12px;
								color: #999999;
							}
						}
						
					}
					.s_item,.e_name{
						border-radius: 0 4px 4px 0;
						margin:4px;
						padding: 12px 8px;
						height: 72px;
					}
					.s_item{
						width: 128px;
						position: relative;
						font-size: 12px;
						border-left: 2px solid rgb(26, 76, 236);
						background: rgba(26, 76, 236, 0.3);
						.delStyle{
							
							position: absolute;
							width: 100%;
							border-radius: 0 4px 4px 0;
							height: 100%;
							top: 0;
							color: white;
							left: 0;
							text-align: center;
							background: rgba(0,0,0,0.4);
							padding-top: 20%;
							
						}
						.s_duration{
							padding: 8px 0;
						}
					}
					
				}
			}
		}
		
		
	}
</style>