<template>
	<!--批量排班-->
	<div class="bt-schedule">
		<el-dialog class='diyModel' title="人员排班" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="500px" :before-close="closeMypup">
			<div style="position: relative;">
				<div class="p_name">{{name}}</div>
				<div style="margin: 10px 0;">
					<div class="it_shits" v-for="(it,dex) in shiftsArrs" :key='dex' :style="{color:it.shiftColor}">
						<div class="it_name" >
							<div class="txNowrap">{{it.shiftName}} ( {{it.shiftShortName}} )</div>
							<div class="txNowrap tx_time">
								<span v-for="(_tt,dex2) in it.workTimeArray">
									<span v-if="dex2>0">/</span>
									<span v-if="_tt.startTime">{{_tt.startTime.time}}</span>-
									<span v-if="_tt.endTime">{{_tt.endTime.time}}</span>
								</span>
							</div>
						</div>
						<el-date-picker style='width: 75%;' type="dates" 
							v-model="it.times" placeholder="选择日期"
							:default-value='month' 
							:picker-options="pickerOptions"></el-date-picker>
					</div>
				</div>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='getDatas' > 保存</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			month:{
				type: String|Date,
				default: '',
			},
			name:{
				type: String,
				default: '',
			}
		},
		components: {},
		data() {
			return {
				shiftsArrs:[
//					{
//						shiftName:'A班次',
//						times:[],
//					},
//					{
//						shiftName:'B班次',
//						times:[],
//					},
				],
				monthEnd:null,
				pickerOptions:{
					disabledDate: (time) => {
				        let nowData = new Date();
				        let dataMonth=new Date(this.month).getMonth()
				        nowData = new Date(nowData.setDate(nowData.getDate() - 1));
				        if(nowData.getMonth()!==dataMonth){
				        	nowData=new Date(this.month);
				        }
				        let endTime=new Date('2023-03-01').getTime();
				        return time < nowData||(this.monthEnd&&time>this.monthEnd)
			       	}
				}
			}
		},
		
		computed: {},
		watch:{
			dataId: {
		      	deep: true, // 深度监听
		      	handler(val,oval) {
		      		this.getShiftsByEmpId();
		      		
		      	}
		   	},
			
		},
		created() {
//			console.log(99999999999999999,this.month,this.common.getTimeDay(new Date(this.month)));
			let d=new Date(this.month);
			
			let year=d.getFullYear(),month=d.getMonth()+1;
			let dayNum = new Date(year,month,0).getDate();
			let _t=new Date(year+'-'+month+'-'+dayNum).getTime();
			if(_t){
				this.monthEnd=_t
			}
			this.getShiftsByEmpId();
		},
		mounted() {},
		
		methods: {
			getDatas(){
				let notCheck=true;
				this.shiftsArrs.map(it=>{
					it.dayShifts=[];
					it.times=it.times||[];
					it.times.map(d=>{
						let day=this.common.getTimeDay(d).slice(-2);
						it.dayShifts.push(+day)
						if(notCheck){
							notCheck=false;
							console.log('有数据',notCheck,d)
						}
					})
				})
				
				if(notCheck){
					this.$message({type:'warning',message:'请选择数据'});
					return;
				}
				this.$emit('setBctchData',this.shiftsArrs);
				
			},
			async getShiftsByEmpId(){
				//通过员工id 获取排班数据
				let params = { empId:this.dataId };
	  			let res = await this.ApiHttp('/scheduling/arrangeShift/findShiftListByEmpId',params);
	  			if(res){
	  				res.map(it=>{
	  					it.times=[];
	  				})
	  				this.shiftsArrs=res;
	  				console.log('获取排班数据2',this.shiftsArrs)
	  			}
			},
			closeMypup(){
				this.$emit('close');
				
				
			},
			
			
		},
		beforeDestroy() {},

	}
</script>

<style lang="scss" scoped>
.bt-schedule{
	.el-dialog__header{
		    text-align: center;
	}
}
.btmBtn{
	text-align: right;
}
.p_name{
	position: absolute;
	top: -22px;
    font-weight: bold;
    color: #333;
}
.it_shits{
	padding-bottom: 12px;
	.it_name{
		width: 24%;
		display: inline-block;
		margin-right: 1%;
		vertical-align: bottom;
    	line-height: 18px;
    	.tx_time{
    		font-size: 12px;
    		color: #CCCCCC;
    	}
	}
}
</style>