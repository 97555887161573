<template>
	<!--更新班次-->
	<div class="Y-change_shifts">
	  	<div class="n_title"> 更换班次</div>
	  	<div class="tips">
	  		<span v-if='!activeShifts'>请选择要更换的班次</span>
	  		<span v-else>请选择替换 [ <span :style="{color:activeShifts.shiftColor?activeShifts.shiftColor:''}">{{activeShifts.shiftName}}</span> ] 的班次</span>
	  	</div>
	  	<div v-if='activeShifts'>
			<AddScheduling :showTbas='false' :empId='empId' :shiftDatas='datas' @setDatas='setChageDatas' />
		</div>
	  	
	  	<div v-else class="list_warp">
  			<div class="it_sts" v-for="(it,dex) in shiftListArr" :key='dex' :style="getDiyStyle(it)" v-if='!it.beforeNow'  @click.stop="checkedToChange(it)">
  				<div class="it_name txNowrap">{{it.shiftName}} ( {{it.shiftShortName}} )</div>
				<div class="it_time txNowrap">
					<span v-for="(_tt,dex2) in it.workTimeArray">
						<span v-if="dex2>0">/</span>
						<span v-if="_tt.startTime">{{_tt.startTime.time}}</span>-
						<span v-if="_tt.endTime">{{_tt.endTime.time}}</span>
					</span>
				</div>
  			</div>
  			<div  class="no-data" v-if='tdAllBefore||shiftListArr.length<1'>
				暂无可选班次
			</div>
  			
  		</div>
		
	</div>
</template>
<script>
	import AddScheduling from "./addScheduling.vue";//添加班次或编辑考勤
	export default {
		props: {
			empId: {
				type: String|Number,
				default: '1',
			},
			datas:{
				type: Object,
				default: ()=>{
					return {
						date: "",
						shiftList:[],
						type: 1,
					}
				}
			},
			
		},
		components: {AddScheduling},
		data() {
			return {
				tabsArr:[
					{name:'添加班次',val:'1'},
					{name:'修改考勤',val:'2'},
				],
				activeShifts:null,//选中的需要更换的班次
				activeTabs:'1',
				shiftListArr:[],
				tdAllBefore:false,
			}
		},
		
		computed: {},
		watch:{},
		created() {
			let Arr=JSON.parse(JSON.stringify(this.datas.shiftList));
			let todayStr=this.common.getTimeDay(new Date());
			console.log(todayStr,this.datas)
			
			let nowTime=new Date().getTime();
			let beforeNum=0;//判断是不是全部都是今天过去了的班次
			Arr.map(it=>{
				it.beforeNow=false;//判断班次是否在当天之前  如果是的话 不允许添加
				
				if(this.datas.date==todayStr){
					//如果是同一天的话
					if(it.workTimeArray){
						for(let _ws of it.workTimeArray){
							let t1=new Date(todayStr+' '+_ws.startTime.time).getTime();
							if(_ws.startTime.workDay=='nextDay'){
								//处理次日的时候
								var dd = new Date();
								dd.setDate(dd.getDate() + 1);
								let nexDayStr=this.common.getTimeDay(dd);
								t1=new Date(nexDayStr+' '+_ws.startTime.time).getTime();
							}
							if(t1<nowTime){
								it.beforeNow=true;
								beforeNum++
								break;
							}
						}
					}
					
					
					
				}
				
				
				
				
			})
			console.log(11111,beforeNum,Arr.length)
			if(beforeNum==Arr.length){
				this.tdAllBefore=true;
			}else{
				this.tdAllBefore=false;
			}
			
			this.shiftListArr=Arr;
//			this.shiftListArr.map(it=>{
//				this.$set(it, 'checked', false);
//			})
		},
		mounted() {
			APP.$emit('countPoverXy');
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			changeTbas(it){
				this.activeTabs=it.val;
				console.log('it',it)
				
			},
			checkedToChange(shifts){
				
				console.log('选中某个班次 去更新',shifts)
				this.activeShifts=JSON.parse(JSON.stringify(shifts));
			},
			closeEvsPopover(){
				
				document.elementFromPoint(50, 60).click();
				
			},
			getDiyStyle(sfs) {
				if(!sfs){
					return ''
				}
				let color=sfs.shiftColor;//sfs.shiftColor;
				let bg = this.common.hexToRgba(color);
				return {
					'background': bg,
					'border-left': '3px solid ' + color
				}
			},
			setChageDatas(val){
				console.log('AAA',val)
				//设置子组件 传过来选中的班次 或者考勤信息数据
				
				let obj={
					empId:this.empId,
					changeBefore:this.activeShifts,
					changeAfter:val,
				}
				this.$emit('saveChangeShifts',obj)
			},
			
		},

	}
</script>

<style lang="scss" scoped>
.Y-change_shifts{
	.n_title{
		padding: 0 20px;
		line-height: 46px;
		font-size: 14px;
		color: #2F3941;
		box-shadow: 0px 1px 0px 0px #E4E7ED;
	}
	.tips{
		padding: 0 20px;
		line-height: 40px;
		font-size: 12px;
	}
	
}
.tabs_warp{
	display: flex;
	.it_tap{
		padding: 10px;
		line-height: 24px;
		cursor: pointer;
		width: 50%;
		text-align: center;
		font-size: 14px;
	}
	.it_tap:hover{
		color: #409EFF;
	}
	.actived.it_tap{
		color: #1A4CEC;
		border-bottom: 2px solid #1A4CEC;
	}
}
.list_warp{
	max-height: 248px;
	overflow-y: auto;
	padding: 0 20px;
	.it_sts{
		font-size: 12px;
		padding: 5px 0;
		padding-left: 12px;
		height: 40px;
		border-left: 2px solid;
		border-color:#1A4CEC;
		cursor: pointer;
		background: #DDE4FC;
		border-radius: 2px;
		line-height: 16px;
		margin: 10px 0;
		.it_name{
			color: #2F3941;
		}
	}
}
.kq_list{
	.k_it{
		font-size: 12px;
		padding-left: 12px;
		height: 40px;
		line-height: 40px;
		border-left: 2px solid;
		border-color:#1A4CEC;
		cursor: pointer;
		background: #DDE4FC;
		border-radius: 2px;
		margin: 10px 0;
		.k_it_icon{
			margin-right: 10px;
		}
		.k_it_name{
			color: #2F3941;
		}
	}
	
}
.no-data{
	text-align: center;
	margin-top: 24px;
}

</style>