<template>
	<!--批量排班-->
	<div class="st-static">
		<el-dialog class='diyModel' :title="datas.empName+' - 排班统计'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="500px" :before-close="closeMypup">
			<div class="table-body">
				<el-table :data="tableData" style="width: 100%" border>
	      			<el-table-column v-for='(it,dex) in tbHead' :prop="it.keys" :label="it.name" :show-overflow-tooltip='true'>
	      				
      				<template slot="header" slot-scope="s">
						<div class="txNowrap" :title="tbHead[dex].name">
							{{tbHead[dex].name}}
						</div>
					</template>
	      				
	      				
	      			</el-table-column>
	    		</el-table>
			</div>
			<!--<div class="flexDiv">
				<div v-for='(value,key) in staticsObj' class="flexItem">
					<div class="head " :title="key">
						<div class="txNowrap">{{key}}</div>
					</div>
					<div class="vals">{{value}}</div>
				</div>
			</div>-->
		</el-dialog>
	</div>
</template>
<script>
	export default {
		props: {
			datas: {
				type: Object,
				default: ()=>{
					return {
						"empId":'',
	    				"year":'2023',
	    				"month":'2',
	    				'empName':'',
					}
				},
			},
		},
		components: {},
		data() {
			return {
				shiftsArrs:[],
				staticsObj:{
//					'测试2':' 1'
				},
				tbHead:[],
				tableData:[],
			}
		},
		
		computed: {},
		watch:{
			datas: {
		      	deep: true, // 深度监听
		      	handler(val,oval) {
		      		this.initDatasById();
		      		
		      	}
		   	},
			
		},
		created() {
			this.initDatasById();
		},
		mounted() {},
		
		methods: {
			getDatas(){
				
				this.shiftsArrs.map(it=>{
					it.dayShifts=[];
					it.times.map(d=>{
						let day=this.common.getTimeDay(d).slice(-2);
						it.dayShifts.push(+day)
					})
				})
				console.log(6666,JSON.parse(JSON.stringify(this.shiftsArrs)))
				this.$emit('setBctchData',this.shiftsArrs);
				
			},
			
			
			async initDatasById(){
				//通过员工id 获取排班数据
				let params = JSON.parse(JSON.stringify(this.datas))
				delete params.open;
	  			let res = await this.ApiHttp('/scheduling/arrangeShift/empArrangeShiftCount',params,'post');
	  			if(res){
	  				let i=0;
	  				let tbRow={};
	  				for(let keys in res){
	  					i++;
	  					let k1='k'+i;
	  					let obj={keys:k1,name:keys,num:res[keys]};
	  					tbRow[k1]=res[keys];
	  					this.tbHead.push(obj);
	  				}
	  				this.tableData.push(tbRow);
	  				this.staticsObj=res;
	  				console.log('获取排班统计数据',this.tableData,this.tbHead)
	  			}
			},
			closeMypup(){
				this.$emit('close');
				
				
			},
			
			
		},
		beforeDestroy() {},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}
.flexDiv{
	padding-bottom: 24px;
	display: flex;
	.m_name{
		font-size: 16px;
		color: #666;
	}
	.flexItem{
		border: 1px solid #EEEEEE;
		line-height: 40px;
		text-align: center;
		flex: 1;
		.txNowrap{
			max-width: 50%;
		}
		.head{
			background: #CCCCCC;
		}
		.vals{
			
		}
	}
	
}
.st-static .el-table tr{
	background: red !important;
	
}
</style>
<style>
.st-static .el-table thead th{
	background: #F0F7FF !important;
	
}
</style>