var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "Y_popover",
      staticClass: "Y_popover",
      class: _vm.mClass,
      style: _vm.diyStyle,
      attrs: { id: "Y_popover" },
    },
    [
      _vm.datas.open
        ? _c("div", [
            _c("div", { staticClass: "triangle", class: _vm.arrow }),
            _c(
              "div",
              { staticClass: "p-body" },
              [
                _c(
                  "transition",
                  { attrs: { name: "el-fade-in-linear" } },
                  [_vm._t("default")],
                  2
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }