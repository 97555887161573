<template>
	<!--日程排班-->
	<mainBox isScroll @scroll='mypover.open=false'>
		<div class="topSearch">
			<el-row>
				<el-col :span='16'>
					<div class="flexDiv">
						<div class="flexIt">
							
							<el-cascader v-model="seachData.deptId" :props='caderProps' :options="organizArrs" :show-all-levels="false" filterable clearable @change='initDatas'></el-cascader>
							
							<!--<el-select v-model="seachData.companyId" placeholder="请选择公司">
								<el-option v-for='it in companyList' :label="it.companyName" :value="it.companyId"> </el-option>
							</el-select>-->
						</div>
						<div class="flexIt" >
							<el-select @focus="poverClose" v-model="seachData.attendanceGroupId" placeholder="请选择考勤组" clearable @change='initDatas'>
								<el-option label="全部考勤组" value="-1"></el-option>
								<el-option v-for='it in attendGroupArrs' :label="it.attendanceGroupName" :value="it.id"></el-option>
							</el-select>
						</div>
						<div class="flexIt">
							<el-date-picker v-model="seachData.month" type="month" placeholder="选择月份" @change='initDatas' :clearable='false'></el-date-picker>
						</div>
						<div class="flexIt">
							<el-input v-model="seachData.empName" :autofocus='atInput' placeholder="请输入" @change='initDatas' clearable></el-input>
						</div>
						<!--<div class="flexIt">
							<el-button class="itBtn" type="primary" :disabled='tbloading' icon="el-icon-search"  @click="initDatas">{{$t("commons.search")}}</el-button>
						</div>-->

					</div>
				</el-col>
				<el-col :span='8' style='text-align: right;' v-if='htmlType==1'>
					<el-button class='plainBtn' plain @click='exportShifts'>导出已发布排班</el-button>
					<el-button class='plainBtn' @click='lookIsReleased' plain>查看已发布排班</el-button>
				</el-col>
			</el-row>
			<div style='margin:18px 0;' >
				<el-row  >
				<el-col :span='16'>
					<el-button type="primary" size='mini'  @click.stop='allowSchedule=!allowSchedule' v-if='htmlType==1' style='margin-right: 18px;'>
						<span v-if="!allowSchedule">开始排班</span>
						<span v-else>结束排班</span>
					</el-button>
					<span class="ck_df">
						<el-checkbox v-model="seachData.defaultAttendanceGroup"  @change='initDatas' true-label='1' false-label='0'>默认考勤组</el-checkbox>
					</span>
				</el-col>
				<el-col :span='8' style='text-align: right;' v-if='htmlType==1'>
					<span style="margin-right: 24px;">当前状态:
						<span v-if="needRelease">待发布</span>
						<span v-else>已发布</span>
					</span>
					<el-button type="primary" @click.stop='toReleaseShifts' v-if="needRelease">发布</el-button>
				</el-col>
			</el-row>
			</div>
			
		</div>
		<div class="shift_date" ref='shiftTable'>
			<el-table class='tbDate' id='listDiv' v-loading='tbloading' :data="tableData" style="width: 100%" border :span-method="mergeRowMethod" :max-height='460'>
				<el-table-column class-name='colGroup' label="考勤组" prop="sliceName" width='26'>
					<template slot-scope="s">
						<div :title="s.row.attendanceName">{{s.row.sliceName}}</div>
					</template>
				</el-table-column>
				<el-table-column class-name='peoplecol' label="人员信息" prop="empName" width='128'>
					<template slot-scope="s">
						<div class="p_warp" @click="openBatchEvens(s.row,$event)">
							<div class="circular">
								{{s.row.empName.substring(s.row.empName.length-1)}}
							</div>
							<div class="p_name " >
								<div class="name txNowrap">{{s.row.empName}}</div>
								<div class="job txNowrap">{{s.row.dutyName||'-'}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column v-for='(it,dex) in tbHeads' :key='it.date' :label="it.name+''" :class-name='getClassName(it.wkDex)' :width='autoCol'>
					<template slot="header" slot-scope="s">
						<div class="hItem" :class="{txRed:it.wkDex<1||it.wkDex==6}">
							<div class="i_d">{{it.name}}</div>
							<div class="i_w">
								<span v-if="it.isToday" class="todayStyle">今天</span>
								<span v-else>
									<span v-if="tableData.length>0&&tableData[0].detailList[dex]&&tableData[0].detailList[dex].holidayName">
										<span class="txholiday">{{tableData[0].detailList[dex].holidayName}}</span>
									</span>
									<span v-else>周{{it.week}}</span>
								</span>
							</div>
						</div>
					</template>
					<template slot-scope="s">
						<div class="itHover" @click.stop='itDayCellClick(s.row,dex,$event)' :class="{actived:acticedCell==String(s.row.empId)+dex}">
								<div v-if="s.row.detailList[dex]">
									<div >
									<el-tooltip slot="reference" class="item" v-if="s.row.detailList[dex].type==1" effect="light" placement="right" popper-class='diytips'>
										<div slot="content" style="max-width: 240px;">
											<div class="s_tipWarp">
												<div class="it_tips" v-for="(itm,dx2) in s.row.detailList[dex].shiftList" :key='dx2'>
													<div class="sf_name">
														<span class="_dot" :style="{'background':itm.shiftColor}"></span>
														<span class="tp_n txNowrap">{{itm.shiftName}} ( {{itm.shiftShortName}} )</span>
														<span class="wait_up" v-if='itm.waitUp' >待发布</span>
													</div>
													<div class="sf_duration txNowrap">{{itm.shiftDuration}}</div>
													<div class="sf_time" :title="getWorkTimeTips(itm)">
														<span v-for="(_tt,dex2) in itm.workTimeArray">
															<span v-if="dex2>0">/</span>
															<span v-if="_tt.startTime">{{_tt.startTime.time}}</span>-
															<span v-if="_tt.endTime">{{_tt.endTime.time}}</span>
														</span>
													</div>
												</div>
											</div>
											<div v-if="!s.row.detailList[dex].shiftList||s.row.detailList[dex].shiftList.length<1">
												<div class="txGray" v-if="s.row.detailList[dex].yellowDot">已删除</div>
											</div>
										</div>
										<div class="sitWarp" @click="evsShow=true">
											<div v-if="haveYellowDot(s.row.detailList[dex])" class="pointDiv sfChange" ></div>
											<div v-if="haveRedDot(s.row.detailList[dex])" class="pointDiv stayUpdate"></div>
											<div class="itSt" v-for="(sfs,itt) in s.row.detailList[dex].shiftList" :key='itt' :style="getDiyStyle(sfs)">
												<div class="sfIsDfult" v-if="sfs.isDefault||s.row.detailList[dex].shiftList.length<2">{{sfs.shiftShortName}}</div>
											</div>
										</div>
									</el-tooltip>
									</div>
									<div v-if="s.row.detailList[dex].type==2">{{getAttendance(s.row.detailList[dex])}}</div>
									<div v-if="s.row.detailList[dex].type==3">休</div>
								</div>
							<div class="addIcon" v-if="allowSchedule&&!s.row.detailList[dex].isBefore">
								+
							</div>

						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="conutNum">
			<div class="part_title">班次统计</div>
			<div class="ct_warp">
				<div v-for="(it,dex) in countArr" :key='dex' class="statis_row" >
					<div class="flexdiv r_name">
						<div class="n_icon" :style="{background:it.shiftColor}" v-if="it.shiftShortName">{{it.shiftShortName}}</div>
						<div class="n_detail" >
							<div class="_n txNowrap" :class="{r_total:it.isTotal}">{{it.name||'-'}}</div>
							<div class="_t txNowrap" :title="getWorkTimeTips(it)">
								<span v-for="(_tt,dex2) in it.workTimeArray">
									<span v-if="dex2>0">/</span>
									<span v-if="_tt.startTime&&_tt.startTime.time">{{_tt.startTime.time}}</span>-
									<span v-if="_tt.endTime&&_tt.endTime.time">{{_tt.endTime.time}}</span>
								</span>
							</div>
						</div>
					</div>
					<div class="flexdiv" v-for="(_day,_h) in tbHeads" :key='_h' :style="{width:autoCol+'px'}" :class="{t_num:it.isTotal}">
						<span  class="c_num" :class="{'tx_c':it.numArr[_h]}">{{it.numArr[_h]}}</span>	
					</div>
				</div>
			</div>
		</div>
		<div>
			<div class="part_title">备注</div>
			<div>
				<el-input type="textarea" :rows="4" placeholder="请输入..." v-model="txDescription"></el-input>
			</div>
		</div>
		<YPopover :datas='mypover' @pClose='poverClose' >
			<div class="evsWarp" v-if="poverType=='evsShow'">
				<div class="it_evs" v-if="allowAdd" @click.stop="setCellEvent('add')"><i class="el-icon-plus" /> 添加班次</div>
				<div class="it_evs" v-if="allowUpdate" @click.stop="setCellEvent('update')"><i class="el-icon-refresh" />更新班次<span class="red_dot"></span></div>
				<div class="it_evs" @click.stop="setCellEvent('change')"><i class="el-icon-refresh-right" />更换班次 </div>
				<div class="it_evs" @click.stop="setCellEvent('delete')"><i class="el-icon-delete" />删除班次</div>
			</div>
			<div class="evsWarp" v-if="poverType=='batchEvens'">
				<template v-if="htmlType==1">
					<div class="it_evs" v-if="allowBatchAdd" @click.stop="toBathByEmpId" > 批量排班</div>
					<div class="it_evs" @click.stop="clearAllByEmpId" >一键清除</div>
				</template>
				
				<div v-else class="it_evs" @click.stop="openStatistics">排班统计</div>
			</div>
			<div class="addShifts" style="width: 300px;" v-if="poverType=='add'">
				<AddScheduling :empId='operateRow.empId' :shiftDatas='operateRow.shiftData' @setDatas='setAddCheckDatas' />
			</div>
			<div class="addShifts" style="width: 440px;" v-if="poverType=='update'">
				<UpdateShift :datas='operateRow.shiftData' @toUpadete='shiftToUpadete'/>
			</div>
			<div class="addShifts" style="width: 300px;" v-if="poverType=='change'">
				<ChangeShift :empId='operateRow.empId' :datas='operateRow.shiftData' @saveChangeShifts='saveChangeShifts'/>
			</div>
			<div class="addShifts" style="width: 360px;" v-if="poverType=='delete'">
				<deleteShift @delData='delShifts' :datas='operateRow.shiftData'/>
			</div>
		</YPopover>
		<batchSchedule v-if='openBatchModel' :isOpen='openBatchModel' @setBctchData='setBctchData' @close='openBatchModel=false' :month='seachData.month' :dataId='batchEmpId' :name='batchEmpName' />
		<shiftsStatistics v-if='statisticInfo.open'  :isOpen='statisticInfo.open' @close='statisticInfo.open=false' :datas='statisticInfo'></shiftsStatistics>
		<waitRelease  v-if='showDrawer' :isOpen='showDrawer'  @rest='initDatas' :seachData='seachData' @close='closeWaitModel'></waitRelease>
	</mainBox>

</template>

<script>
	import {getOrganizationTree} from "@/api/ruge/tenant/organization";
	import { listToTree } from "@/utils/tree";
	import AddScheduling from "./model/addScheduling.vue";//添加班次或编辑考勤
	import UpdateShift from "./model/updateShift.vue";//更新班次
	import ChangeShift from "./model/changeShift.vue";//更新班次
	import deleteShift from "./model/deleteShift.vue";//更新班次
	import shiftsStatistics from "./model/shiftsStatistics.vue";//更新班次
	import YPopover from "./model/YPopover.vue";
	import batchSchedule from "./model/batchSchedule.vue";
	import waitRelease from "./model/waitRelease.vue";//待发布更新
	import {getCompanyList,getProjectList} from "@/api/business/order/tenant/order";
	export default {
		mixins: [],
		components: {AddScheduling,UpdateShift,ChangeShift,deleteShift,YPopover,batchSchedule,shiftsStatistics,waitRelease},
		data() {
			return {
				htmlType:1,//1 草稿 2发布
				allowSchedule:false,//是否允许排班
				mypover:{
					open:false,
					x:0,
					y:0,
				},
				autoCol: 50,
				tbHeads: [],
				nullNumArr:[],
				tableData: [],
				tbloading: false,
				countArr:[],//班次统计数量
				seachData: {
//					companyId: '',
//					attendanceGroupId:'-1',
//					empName: '',
//					month: '',
//					yNum:'',
//					deptId:'',
//					mNum:'',
//					defaultAttendanceGroup:'0',
				},
				txDescription:'',//备注信息
				visible: false,
				cellEvs: '',
				evsShow: true,
				atInput: false,
				today:new Date(),
				todayNum:0,//当天的时间戳  ，不带时分秒
				acticedCell:'',
				poverType:'',
				companyList:[],
				attendGroupArrs:[],
				operateRow:{
					//操作的数据信息  empId员工id  dayDex第几天的 shiftData排班数据
					empId:'',
					dayDex:0,
					shiftData:{},
				},
				allowAdd:true,//是否允许添加
				allowBatchAdd:true,//是否允许添加
				allowUpdate:true,//是否允许更新
				batchEmpId:'',//批量操作的角色id，用于批量排班，批量清除
				batchEmpName:'',
				openBatchModel:false,//批量排班弹框
				isIniting:false,//请求数据中
				
				statisticInfo:{
					open:false,
					year:'',
					month:'',
					empId:'',
					empName:'',
				},
				showDrawer:false,
				caderProps:{value:'deptId',label:'name',children:'children',checkStrictly:true,emitPath: false},
				organizArrs:[],//组织架构
				
				needRelease:false,//当前月份有需要发布的数据
			};
		},
		computed: {
			routeQuery(){
				return this.$route.query;
			},
		},
		watch: {
		 	tableData: {
		      	deep: true, // 深度监听
		      	handler(val,oval) {
		      		console.log('tableData--change')
		      		if(!this.isIniting&&this.htmlType<2){
		      			this.saveDataByType();
		      		}
		      		this.conutShitsNum();
		      	}
		   	},
		   	routeQuery: {
		      	deep: true, // 深度监听
		      	handler(val,oval) {
		      		this.initHtml();
		      	}
		   	},
		},
		created() {
			this.initHtml();
		},
		mounted() {
			
			this.$nextTick(()=>{
				let  fixedDoms = document.getElementById('listDiv').getElementsByClassName('el-table__body-wrapper');
				
				for(let it of fixedDoms){
					it.addEventListener('scroll',()=>{
						this.mypover.open=false;
					},false);
				}
				
				
			})
			
		},
		methods: {
			async initHtml(){
				console.log('重置页面')
				this.initSetSeachData();
				let qData=this.$route.query;
				if(qData){
					//查看已发布时数据需要的参数
					if(qData.htmlType){
						this.htmlType=qData.htmlType;
					}else{
						this.htmlType=1;
					}
					if(qData.deptId) qData.deptId=+qData.deptId;
					
					if(qData.attendanceGroupId>0) qData.attendanceGroupId=+qData.attendanceGroupId;
					
					this.seachData={...this.seachData,...qData};
				}
				
				
				this.initOrganizationDatas();
				if(this.today){
					this.todayNum=new Date(this.common.getTimeDay(this.today)).getTime();
				}
				this.initCompanyList();
				await this.initAttendanceGroups();
				this.initDatas();
			},
			initSetSeachData(){
				this.seachData={
					companyId: '',
					attendanceGroupId:'-1',
					empName: '',
					month: '',
					yNum:'',
					deptId:'',
					mNum:'',
					defaultAttendanceGroup:'0',
				};
				let year=this.today.getFullYear(),month=this.today.getMonth()+1;
				this.seachData.month=year+'-'+month;
			},
			async initDatas() {
				let d=new Date(this.seachData.month);
				let year=d.getFullYear(),month=d.getMonth()+1;
				this.seachData.yNum=year;
				this.seachData.mNum=month;
				let  dayNum = new Date(year,month,0).getDate();
				let params={
					"status":'0',
				    "year":year,
				    "month":month,
				    'deptId':this.seachData.deptId,
            'attendanceGroupId':this.seachData.attendanceGroupId,
    				"empName":this.seachData.empName,
    				"defaultAttendanceGroup":this.seachData.defaultAttendanceGroup,
//  				isloading:true,
    				
				}
				if(params.deptId<0){
					//应后台要求， 需要在选中值的时候 区分是选中的部门 还是公司，并且传入的参数值也不一样 !!!!
					params.companyId=this.findCompanyIdBydeptId(params.deptId);
					this.seachData.companyId=params.companyId;
					console.log('----------',params)
				}
				if(this.htmlType==2){
					//查看已发布的数据
					params.status='1';
				}
				this.isIniting=true;
				this.autoCol = Math.floor((this.$refs.shiftTable.offsetWidth - 168) / dayNum);
				if(this.autoCol<40) this.autoCol=40;
				let wkArr = ['日', '一', '二', '三', '四', '五', '六'];
				this.nullNumArr=[];
				let todayStr=this.common.getTimeDay(this.today);
				this.tbHeads=[];
				for(let i = 0; i < dayNum; i++) {
					this.nullNumArr[i]=0;
					let d = year+'-'+month+'-' + (i + 1);
					let _day= new Date(d);
					let t = _day.getDay();
					let wk = wkArr[t];
					let obj={
						date:d,
						name: (i + 1),
						week: wk,
						wkDex: t,
						isToday:this.common.getTimeDay(_day)==todayStr,
					}
					this.tbHeads.push(obj)
				}
				if(!year||!month){
					//查询数据不正确
					return;
				}
				this.tbloading=true;
				this.needRelease=false;
				this.tableData=[];
				let res = await this.ApiHttp('/scheduling/arrangeShift/find',params,'post');
				console.log('数据',this.tbHeads,JSON.parse(JSON.stringify(res)))
				//web造数据 start
//				let webArr=[];
//				for(let i=0;i<28;i++){
//					let obj={
//						attendance: null,
//						date: "2023-02-0"+(i+1),
//						shiftList: null,
//						type: null,
//					}
//					if(i==2){
//						obj.type=1;
//						obj.shiftList=[
//							{
//								id:1,
//								shiftName: 'A班',
//								shiftShortName: "A",
//								isDefault: false,
//								shiftColor: '#df1aec',
//								shiftDuration: '12小时',
//								workTimeArray:[
//									{startTime: {time: "08:00", workDay: "today"}, endTime: {time: "20:00", workDay: "today"}}
//								],
//							}, 
//						]
//					}
//					if(i==9){
//						obj.type=1;
//						obj.shiftList=[
//							{
//								id:2,
//								shiftName: 'B班',
//								shiftShortName: "B",
//								isDefault: false,
//								shiftColor: '#ff0000',
//								shiftDuration: '12小时',
//								workTimeArray:[
//									{startTime: {time: "21:00", workDay: "today"}, endTime: {time: "23:59", workDay: "today"}}
//								],
//							}, 
//							{
//								id:5,
//								shiftName: 'C班',
//								shiftShortName: "B",
//								isDefault: false,
//								shiftColor: '#F8C85B ',
//								shiftDuration: '12小时',
//								workTimeArray:[
//									{startTime: {time: "21:00", workDay: "today"}, endTime: {time: "23:59", workDay: "today"}}
//								],
//							}, 
//						]
//					}
//					webArr.push(obj);
//				}
//				let res ={
//					description: 'AAAAA',
//					month: 2,
//					year: 2023,
//					arrangeShiftEmployeeList:[
//						{
//							attendanceId: 3,
//							attendanceName: "考勤组A",
//							detailList: webArr,
//							empId: 118,
//							empName: "谢华",
//						}
//					],
//				}
				//web造数据 end
				this.tbloading=false;
				
				this.txDescription=res.description||'';
				if(!res.arrangeShiftEmployeeList) res.arrangeShiftEmployeeList=[];
				let datas = this.common.quickSort(res.arrangeShiftEmployeeList, 'attendanceId');
				let mergeObj = {};
				let tdNum=new Date(this.common.getTimeDay(this.today)).getTime();
				datas.map((item, dex) => {
					if(!mergeObj[item.attendanceId]) {
						mergeObj[item.attendanceId] = {
							startDex: dex,
							num: 0
						}
					}
					if(item.detailList){
						item.detailList.map(itd=>{
							itd.isBefore=tdNum>new Date(itd.date).getTime();
						})
					}
					
					
					mergeObj[item.attendanceId].num++;
				})

				for(let keys in mergeObj) {
					let it = mergeObj[keys];
					
					let item=datas[it.startDex];
					item.attendanceName=item.attendanceName||'';
					let len=item.attendanceName.length;
					item.sliceName=item.attendanceName;
					if(len>3&&it.num<2){
						item.sliceName=item.attendanceName.slice(0,3)+' ...';
					}
					item.mergenum = it.num;
				}
				console.log('mergeObj',JSON.parse(JSON.stringify(mergeObj)))
				this.tableData = datas;
				setTimeout(()=>{
					this.isIniting=false;
				},1000)
				console.log('http数据', JSON.parse(JSON.stringify(datas)))
			},
			conutShitsNum(){
				//统计班次数量
				let numObj={};
				let totalObj={
					name:'合计',
					isTotal:true,
					numArr:JSON.parse(JSON.stringify(this.nullNumArr)),
				}
				this.tableData.map(it=>{
					if(it.detailList){
						it.detailList.map((_day,_sort)=>{
							if(_day.shiftList){
								_day.shiftList.map((_sft,dex)=>{
									let keys=_sft.id;
									if(!numObj[keys]){
										let nArr=JSON.parse(JSON.stringify(this.nullNumArr));
										nArr[_sort]++;
										numObj[keys]={
											name:_sft.shiftName,
											shiftShortName:_sft.shiftShortName,
											numArr:nArr,
											shiftColor:_sft.shiftColor,
											workTimeArray:_sft.workTimeArray,
										};
									}else{
										numObj[keys].numArr[_sort]++;
									}
									totalObj.numArr[_sort]++;
								})
							}
							
//							numObj[keys].num++;
						})
						
					}
				})
				this.countArr=[];
				for(let keys in numObj){
					this.countArr.push(numObj[keys])
				}
				this.countArr.push(totalObj);
				console.log('countArr',this.countArr)
				
				
			},
			
			checkShiftIsStartd(shiftData){
				//shiftData是数组  校验几个班次对比于当前时间   workTime是否存在已经开始了
				
				console.log(555555555,shiftData)
				let resAllow=false;
				let shiftDay=this.operateRow.shiftData.date;
				let nTime=new Date().getTime();
				for(let _ss of shiftData){
					if(_ss&&_ss.workTimeArray){
						for(let _ws of _ss.workTimeArray){
							let t1=new Date(shiftDay+' '+_ws.startTime.time).getTime();
  							if(_ws.startTime.workDay=='nextDay'||_ws.endTime.workDay=='nextDay'){
  								//处理次日的时候
  								var dd = new Date();
								dd.setDate(dd.getDate() + 1);
								let nexDayStr=this.common.getTimeDay(dd);
								if(_ws.startTime.workDay=='nextDay'){
									t1=new Date(nexDayStr+' '+_ws.startTime.time).getTime();
								}
  							}
  							if(t1<nTime){
  								//如果每个时间段的开始时间 小于当前时间 代表已经开始  
  								resAllow=true;
  								break;
  							}
						}
					}
					if(resAllow) break;
				}
				return resAllow;
			},
			
			
			async shiftToUpadete(vals){
				//选中一组班次进行更新，vals{before，now}
				let cellData=this.findOperateData(this.operateRow.empId) ;
				let shiftData=JSON.parse(JSON.stringify(cellData.shiftList));
				let resArr=[];//记录更新的
				
//				if(!vals.now.workTimeArray){
//					vals.now.workTimeArray=JSON.parse(vals.now.workTime);
//				}
				
				let isStartd=this.checkShiftIsStartd([vals.now]);
				if(isStartd){
					this.$message({type:'warning',message:'班次已经开始 操作不允许'});
					return;
				}
				shiftData.map((_s,dex)=>{
					if(_s.id==vals.before.id){
						//该班次需要更新最新班次 
						delete cellData.shiftChangeMap[_s.id];//删除元素据需要更新的班次信息
						if(vals.now) resArr.push(vals.now);
					}else{
						resArr.push(_s);
					}
				})
				let postArr=[
					{
						"date":this.operateRow.shiftData.date,
    					"empId":this.operateRow.empId,
    					'shiftList':resArr,
					}
				]
				let ruleAllow=await this.verificatShifts(postArr);
				if(!ruleAllow) return;
				
				let len=0;
				for(let k1 in cellData.shiftChangeMap){
					len++;
				}
//				cellData.shiftChangeMap=null;
//				cellData.changeShiftId=null;
				let params={
					"date":this.operateRow.shiftData.date,
					"empId":this.operateRow.empId,
					'shiftId':vals.before.id,
					'changeShiftId':this.operateRow.shiftData.changeShiftId,
				}
				let res=await this.ApiHttp('/scheduling/arrangeShift/clearRedDot',params,'post');
				if(!res){
					return
				}
				this.poverClose();
				this.initDatas();
//				cellData.yellowDot=true;
//				cellData.shiftList=resArr;
				
				console.log('uuuuuuuuuuup',JSON.parse(JSON.stringify(shiftData)));
			},
			async setAddCheckDatas(val){
				//设置子组件 传过来选中的班次 或者考勤信息数据
				console.log(999999999,val)
				let type=val.sendType=='shifts'?'1':'2';
				delete val.sendType
				
				let cellData=this.findOperateData(this.operateRow.empId);
				cellData.type=type;
				if(type==1){
					
					let dayDatas=JSON.parse(JSON.stringify(cellData));
					dayDatas.shiftList=dayDatas.shiftList||[];
					dayDatas.shiftList.push(val);
					let postArr=[{
						"date":this.operateRow.shiftData.date,
						"empId":this.operateRow.empId,
						shiftList:dayDatas.shiftList,
					}]
					let ruleAllow=await this.verificatShifts(postArr);
					if(!ruleAllow) return;
					cellData.attendance=null;
					cellData.shiftList=dayDatas.shiftList;
//					cellData.shiftList=cellData.shiftList||[];
//					cellData.shiftList.push(val);
				}else{
					let params={
						date:this.operateRow.shiftData.date,
    					empId:this.operateRow.empId,
						attendanceVO:val,
						arrangeShiftId:this.operateRow.shiftData.arrangeShiftId,
					}
//					let res=await this.ApiHttp('/scheduling/arrangeShift/attendancePublish',params,'post');
//					if(!res){
//						return
//					}
					cellData.shiftList=[];
					cellData.changeShiftId=null;
					cellData.shiftChangeMap=null;
					cellData.attendance=val;
				}
				cellData.yellowDot=true;
				this.poverClose();
				
//				let shiftData=JSON.parse(JSON.stringify(cellData.shiftList));
//				for(let it of this.tableData){
//					if(it.empId==this.operateRow.empId){
//						let cellData=it.detailList[this.operateRow.dayDex];
//						cellData.type=type;
//						if(type==1){
//							cellData.attendance=null;
//							cellData.shiftList=cellData.shiftList||[];
//							cellData.shiftList.push(val);
//						}else{
//							cellData.shiftList=[];
//							cellData.attendance=val;
//						}
//						cellData.yellowDot=true;
//						console.log('ititititit',it)
//						this.poverClose();
//						return;
//					}
//				}
			},
			openBatchEvens(row,e){
				//点击人员信息， 记录EmpId 弹开对应批量操作框
				
				if(this.htmlType==1&&!this.allowSchedule) return;
				if(row.attendanceType=='2'){
					//排班制的时候才允许批量排班
					this.allowBatchAdd=true;
				}else{
					this.allowBatchAdd=false;
				}
				
				this.batchEmpId=row.empId;
				
				
				
				this.batchEmpName=row.empName;
				this.poverType='batchEvens';
				setTimeout(()=>{
					this.openSchedulingPover(e);
					console.log('打开pup',this.batchEmpId)
				},120)
			},
			toBathByEmpId(){
				//点击人名 批量排班
				this.openBatchModel=true;
				this.poverClose();
			},
			async setBctchData(val){
				//选中批量排班，填充排班信息
				console.log(55555555,JSON.parse(JSON.stringify(val)))
				let nowData=JSON.parse(JSON.stringify(this.tableData));
				let resArr=[];
				let isAllow=true;
				let postArr=[];
				for(let it of nowData){
					if(!isAllow) break;
					if(it.empId==this.batchEmpId){
						//找到操作的哪行 
						for(let _s of val){
							if(!isAllow) break;
							//开始遍历批量操作班次选中的日期 dayShifts
							if(!_s.isDefault&&_s.dayShifts){
								for(let num of _s.dayShifts){
									let rowData=it.detailList[num-1];
									rowData.type='1';
									if(!rowData.shiftList){
										rowData.shiftList=[];
									}
									_s.waitUp='1';
									rowData.shiftList.push(_s);
									if(rowData.shiftList.length>3){
										isAllow=false;
									}
									it.detailList[num-1].isBatch=true;
									it.detailList[num-1].yellowDot=true;
									if(!isAllow) break;
								}
								
//								_s.dayShifts.map(num =>{
//									let rowData=it.detailList[num-1];
//									rowData.type=1;
//									if(!rowData.shiftList){
//										rowData.shiftList=[];
//									}
//									rowData.shiftList.push(_s);
////									rowData.shiftList.push({
////										name: 'D班',
////										isDf: true,
////										color: '#ffc0cb',
////										tiemlong: '12小时',
////										timeRange: ['9.00;12.00', '14.00;18.00'],
////									})
//								})
							}
							
						}
						if(!isAllow){
							this.$message({type:'warning',message:'每日班次数量不能超过3个'})
							return;
						}
						
						it.detailList.map(it=>{
							if(it.isBatch){
								postArr.push({
									date:it.date,
									shiftList:it.shiftList,
									empId:this.batchEmpId,
								})
							}
						})
						
					}
				}
				console.log('888888',JSON.parse(JSON.stringify(nowData)))
				
				
				let ruleAllow=await this.verificatShifts(postArr);
				if(!ruleAllow) return;
				let d=new Date(this.seachData.month);
				let bcParams={
					description:this.txDescription,
					arrangeShiftEmployeeList:nowData,
					"year": this.seachData.yNum,
				  	"month": this.seachData.mNum,
					"empId":this.batchEmpId,
				}
				let httpRes=await this.ApiHttp('/scheduling/arrangeShift/batchDraftArrangeShift',bcParams,'post');
				if(httpRes){
					this.openBatchModel=false;
					this.initDatas();
				}
//				if(ruleAllow){
//					this.openBatchModel=false;
//					this.tableData=nowData;
//				}
//				let httpRes=await this.ApiHttp('/scheduling/arrangeShift/checkRule',postArr,'post');
//				if(httpRes.status){
//					this.openBatchModel=false;
//					this.tableData=nowData;
//				}else{
//					this.$message({type:'warning',message:httpRes.msg})
//				}
			 	
//			    let res = await this.saveDataByType(nowData);
//			    console.log(55555555,res);
//			    if(res.code==200){
//			    	this.openBatchModel=false;
//			    	this.initDatas();
//			    }
//			    console.log('nowDatanowData',JSON.parse(JSON.stringify(nowData)))
//				this.openBatchModel=false;
//				this.tableData=nowData;
			},
			async verificatShifts(postArr){
				//校验班次是否成立 postArr=[ { date,empId,shiftList}]
				let  Allow=false;
				let httpRes=await this.ApiHttp('/scheduling/arrangeShift/checkRule',postArr,'post');
				if(httpRes.status){
					Allow=true;
				}else{
					this.$message({type:'warning',message:httpRes.msg})
				}
				return Allow
				
			},
			
			async clearAllByEmpId(){
				//一键清除某个人的所有当月所有排班
				let params={
					"year": this.seachData.yNum,
				  	"month": this.seachData.mNum,
					"empId":this.batchEmpId,
				}
				let res = await this.ApiHttp('/scheduling/arrangeShift/oneClickClear',params,'post');
				console.log('saveChangeShifts',res)
				if(res.status){
					this.poverClose();
					this.initDatas();
				}else{
					this.$message({type:'error',message:res.msg})
				}
				
				
				
				
				
				
//				for(let it of this.tableData){
//					if(it.empId==this.batchEmpId){
//						let listArr=JSON.parse(JSON.stringify(it.detailList));
//						let nTime=new Date().getTime();
//						let tTime=new Date(this.common.getTimeDay(this.today)).getTime();//取出当天的时间 判断是否当天 
//						listArr.map(sft => {
//							//清空所有排班 考勤数据
//							if(sft.shiftList){
//								
//								let _dd=new Date(sft.date).getTime();
//								if(_dd>nTime){
//									sft.attendance=null;
//									sft.shiftList=[];
//									//数据发生过变动，需要展示黄点
////									console.log('---------数据发生过变动，',sft.date)
//									sft.yellowDot=true;
//								}else{
//									if(_dd==tTime){
//										//如果是当天  责在判断已有班次是否已开始 如果开始了 则清除
//										let filterArr=[];//找出符合条件的班次 ，不予清除
//										if(sft.shiftList){
//											sft.shiftList.map((_ss=>{
//												//先遍历有的班次， 
//												if(_ss.workTimeArray){
//													for(let _ws of _ss.workTimeArray){
//														let t1=new Date(sft.date+' '+_ws.startTime.time).getTime();
//	//						  							let t2=new Date(sft.date+' '+_ws.endTime.time).getTime();
//							  							if(_ws.startTime.workDay=='nextDay'||_ws.endTime.workDay=='nextDay'){
//							  								//处理次日的时候
//							  								var dd = new Date();
//					        								dd.setDate(dd.getDate() + 1);
//					        								let nexDayStr=this.common.getTimeDay(dd);
//					        								if(_ws.startTime.workDay=='nextDay'){
//					        									t1=new Date(nexDayStr+' '+_ws.startTime.time).getTime();
//					        								}
//	//				        								if(_ws.endTime.workDay=='nextDay'){
//	//				        									t2=new Date(nexDayStr+' '+_ws.endTime.time).getTime();
//	//				        								}
//							  							}
//							  							if(t1<nTime){
//							  								//如果每个时间段的开始时间 小于当前时间 代表 该班次已经开始 不能被删除  并且存入结果中 最后在重新赋值
//							  								filterArr.push(_ss);
//							  								break;
//							  							}
//													}
//												}
//											}))
//											if(sft.shiftList.length!==filterArr.length){
//												//数据发生过变动，需要展示黄点
//												sft.yellowDot=true;
//											}
//											console.log('filterArr',filterArr)
//											sft.shiftList=filterArr;
//										}
//									}
//								}
//							}
//						})
//						console.log('888888',JSON.parse(JSON.stringify(listArr)))
//						it.detailList=listArr;
//						this.$message({type: "success",message: '操作成功'});
//						this.poverClose();
//						break;
//					}
//				}
			},
			openStatistics(){
				//点击打开排班统计
				this.mypover.open=false;
				this.statisticInfo={
					open:true,
					year:this.seachData.yNum,
					month:this.seachData.mNum,
					empId:this.batchEmpId,
					empName:this.batchEmpName,
				};
				
				
			},
			async itDayCellClick(row,dex,e){
				//点击某一天 唤起对应事件  
				if(!this.allowSchedule) return;
				let shiftData=JSON.parse(JSON.stringify(row.detailList[dex]));
				if(shiftData.isBefore){
					this.$message({type:'warning',message:'无法为当前之前的时间操作班次'})
					return;
				}
				
				if(!shiftData.shiftList) shiftData.shiftList=[];
				let len=shiftData.shiftList.length;
				
				if(shiftData.shiftChangeMap){
					this.allowUpdate=true;
					
				}else{
					this.allowUpdate=false;
				}
				if(len<1){
					this.poverType='add';
				}else{
					if(len>2){
						this.allowAdd=false;
					}else{
						this.allowAdd=true;
					}
					this.poverType='evsShow';
				}
				this.operateRow={
					empId:row.empId,
					dayDex:dex,
					shiftData:shiftData,
				}
				
				console.log(this.operateRow)
				this.openSchedulingPover(e);
				
				this.acticedCell=String(row.empId)+String(dex);
				
				
			},
			findOperateData(empId){
				//通过empId查找当前操作的是哪个数据
				let res=null;
				for(let it of this.tableData){
					if(it.empId==empId){
						res=it.detailList[this.operateRow.dayDex];
						break;
					}
				}
				return res;
			},
			
			async saveChangeShifts(val){
				//更换班次 ，组件回调  val={empId,before,after}
				let params={
    			'dateStr': this.operateRow.shiftData.date,
					'empId':this.operateRow.empId,
					'beforeShift':val.changeBefore,
					'afterShift':val.changeAfter,
				}
				let res = await this.ApiHttp('/scheduling/arrangeShift/changeShift',params,'post');
				console.log('saveChangeShifts',res)
				if(res.status){
					this.poverClose();
					this.initDatas();
				}else{
					this.$message({type:'error',message:res.msg})
				}
				
//				let cellData=this.findOperateData(this.operateRow.empId) ;
//				let shiftData=JSON.parse(JSON.stringify(cellData.shiftList));
//				let resArr=[];
//				let exceptArr=[];//例如原先有123 除开需要更换的班次1 等于23    将1更换成4  在4添加进去 要与23时间进行比较
//				
//				if(shiftData){
//					shiftData.map((_ss,dex)=>{
//						if(_ss.id==val.changeBefore.id){
//							resArr.push(val.changeAfter)
//						}else{
//							exceptArr.push(_ss);
//							resArr.push(_ss);
//						}
//					})
//				}
//				for(let it of exceptArr){
//					if(val.changeAfter.id==it.id){
//						this.$message({type:'warning',message:'存在相同班次'})
//						return;
//					}
//				}
//				
//				
//				let postArr=[
//					{
//						"date":this.operateRow.shiftData.date,
//  					"empId":this.operateRow.empId,
//  					'shiftList':resArr,
//					}
//				]
//				let ruleAllow=await this.verificatShifts(postArr);
//				if(!ruleAllow) return;
//				cellData.yellowDot=true;
//				cellData.shiftList=resArr;
//				this.poverClose();
				
				
//				let addAllow=this.common.diffTimeAllow(exceptArr,[val.changeAfter])
//				if(addAllow){
//					cellData.shiftList=resArr;
//					this.poverClose();
//				}else{
//					this.$message({type:'warning',message:'班次时间冲突~'})
//				}
				
				
				
//				for(let it of this.tableData){
//					if(it.empId==this.operateRow.empId){
//						let cellData=it.detailList[this.operateRow.dayDex];
//						let shiftData=JSON.parse(JSON.stringify(cellData.shiftList));
//						let resArr=[];
//						if(shiftData){
//							shiftData.map((_ss,dex)=>{
//								if(_ss.id==val.changeBefore.id){
//									resArr.push(val.changeAfter)
//								}else{
//									resArr.push(_ss);
//								}
//							})
//						}
//						cellData.shiftList=resArr
//						this.poverClose();
//						break;
//					}
//				}
				
				
//				for(let it of this.tableData){
//					if(it.empId==val.empId){
//						let cellData=it.detailList[this.operateRow.dayDex];
//						if(cellData&&cellData.shiftList){
////							for(let _sft of cellData.shiftList){
////								if(_sft.id==val.changeBefore.id){
////									console.log('BBBB',_sft)
////									_sft=val.changeAfter;
////									break;
////								}
////							}
//							
//							cellData.shiftList.map((_sft,dex1)=>{
//								if(_sft.id==val.changeBefore.id){
//									console.log('BBBB',_sft)
//									cellData.shiftList[dex1]=val.changeAfter;
//								}
//								
//							})
//						}
//						break;
//					}
//				}
				console.log('99999++++++',this.tableData)
				
//				let cellData=this.findOperateData(val.empId);
//				if(cellData&&cellData.shiftList){
//					for(let it of cellData.shiftList){
//						if(it.id==val.changeBefore.id){
//							it=val.changeAfter;
//							break;
//						}
//					}
//				}
				
				
				
			},
			getAttendance(row){
				//获取考勤符号信息
				let res='';
				if(row&&row.attendance){
					if(row.attendance.attendanceSign){
						console.log('row.attendance',row.attendance)
						res=row.attendance.attendanceSign;
					}
				}
				return res;
				
			},
			async	delShifts(val){
				//删除班次 ，组件回调
				let params={
    			'dateStr': this.operateRow.shiftData.date,
					'empId':this.operateRow.empId,
					'deleteShiftList':val,
				}
				let res = await this.ApiHttp('/scheduling/arrangeShift/deleteShift',params,'post');
				console.log('saveChangeShifts',res)
				if(res.status){
					this.poverClose();
					this.initDatas();
				}else{
					this.$message({type:'error',message:res.msg})
				}
				
				
				
				
				//之前前端处理是传ID
//				let nTime=new Date().getTime();
//				let delAllow=true;
//				for(let it of this.tableData){
//					if(it.empId==this.operateRow.empId){
//						let cellData=it.detailList[this.operateRow.dayDex];
//						let shiftData=JSON.parse(JSON.stringify(cellData.shiftList));
//						let resArr=[];
//						if(shiftData){
//							for(let _ss of shiftData){
//								
//								
//								if(val.indexOf(_ss.id)<0){
//									resArr.push(_ss);
//								}else{
//									
//									if(_ss.workTimeArray){
//										for(let _ws of _ss.workTimeArray){
//											let t1=new Date(cellData.date+' '+_ws.startTime.time).getTime();
//				  							if(_ws.startTime.workDay=='nextDay'||_ws.endTime.workDay=='nextDay'){
//				  								//处理次日的时候
//				  								var dd = new Date();
//			    								dd.setDate(dd.getDate() + 1);
//			    								let nexDayStr=this.common.getTimeDay(dd);
//			    								if(_ws.startTime.workDay=='nextDay'){
//			    									t1=new Date(nexDayStr+' '+_ws.startTime.time).getTime();
//			    								}
//				  							}
//				  							if(t1<nTime){
//				  								//如果每个时间段的开始时间 小于当前时间 代表已经开始  不可以被删除
//				  								delAllow=false;
//				  								console.log('delAllow*------',cellData.date,t1,nTime)
//				  								break;
//				  							}
//										}
//									}
//									
//								}
//							}
//						}
//						console.log('delAllow',delAllow,resArr)
//						if(delAllow){
//							cellData.yellowDot=true;
//							cellData.shiftList=resArr
//							console.log('cellData222',cellData)
//							this.poverClose();
//							return;
//						}else{
//							this.$message({type:'warning',message:'不允许删除'})
//						}
//						
//					}
//				}
			},
			async saveDataByType(appointShift){
				//提交保存数据  1草稿  2发布  appointShift = 指定传入的数据 ，用于批量添加校验
				let d=new Date(this.seachData.month);
				let params={
					year: d.getFullYear(),
					month: d.getMonth()+1,
					description:this.txDescription,
					arrangeShiftEmployeeList:this.tableData,
					"date":this.operateRow.shiftData.date,
					"empId":this.operateRow.empId,
				}
				if(appointShift) params.arrangeShiftEmployeeList=appointShift;
				let res = await this.ApiHttp('/scheduling/arrangeShift/draft',params,'post');
				console.log('savaData',params)
				return res
			},
			
			async initAttendanceGroups(){
				//获取考勤组数据
				let res = await this.ApiHttp('/scheduling/attendanceGroup/list?isDefault=0');
				console.log('获取考勤组数据',JSON.parse(JSON.stringify(res)))
				if(res){
					if(window.location.host.indexOf('localhost')>-1){
						//ych-本地开发专用
						this.seachData.attendanceGroupId=46;
					}
					
//					if(res.length>0){
//						this.seachData.attendanceGroupId=res[0].id;
//					}
					this.attendGroupArrs=res;
				}
			},
			
			initOrganizationDatas(){
				getOrganizationTree(this.listQuery).then((response) => {
			        let data = listToTree(response, "deptId", "parentId");
			        console.log("xxx1", data);
			        this.organizArrs=data;
			      });
				
				
			},
			
			
			initCompanyList() {
				//加载公司列表
				getCompanyList().then((res) => {
					this.companyList = res;
				});
			},
			openSchedulingPover(e){
				//通过点击打开选择pover框
				this.mypover={
					open:true,
					x:e.clientX+20,
					y:e.clientY-24,
				}
			},
			poverClose(){
				console.log('点击了.....')
				this.mypover.open=false;
				this.acticedCell='';
			},
			async getShiftsByEmpId(id){
				//通过员工id 获取排班数据
				let params = { empId:id };
	  			let res = await this.ApiHttp('/scheduling/arrangeShift/findShiftListByEmpId',params);
	  			if(res){
	  				console.log('获取排班数据',res)
	  			}
			},
			getPopwidth(){
				let w=this.cellEvs?300:68;
				if(this.cellEvs=='update'||this.cellEvs=='delete') w=428;
				return w;
			},
			setCellEvent(type) {
				this.cellEvs = type;
				this.poverType = type;
				this.evsShow = false;
			},
			hexToRgba(hex, opacity) {
				return 'rgba(' + parseInt('0x' + hex.slice(1, 3)) + ',' + parseInt('0x' + hex.slice(3, 5)) + ',' +
					parseInt('0x' + hex.slice(5, 7)) + ',' + opacity + ')';
			},
			getDiyStyle(sfs) {
				let color=sfs.shiftColor;//sfs.shiftColor;
				let bg = this.common.hexToRgba(color);
				return {
					'background': bg,
					'border-left': '3px solid ' + color
				}
			},
			getClassName(dex) {
				let res = 'd_item'
				if(dex < 1 || dex == 6) {
					res = 'd_item isXx'
				}
				return res;
			},
			
			mergeRowMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				//合并表格
				if(columnIndex === 0) {

					if(row.mergenum) {
						return {
							rowspan: row.mergenum,
							colspan: 1
						};
					} else {
						return {
							rowspan: 0,
							colspan: 0
						};
					}
				}

			},
			
			changeTimeString(arrStr){
				let res='';
				console.log(arrStr)
				let arr=JSON.parse(arrStr);
				arr.map(it=>{
					res=res+it.startTime.time+'-'+it.endTime.time+' '
				})
				return res;
			},
			exportShifts() {
				//导出//导出已发布的排班
		      	const params = {
		      		"year":this.seachData.yNum,
				    "month":this.seachData.mNum,
				    "attendanceGroupId": this.seachData.attendanceGroupId,
				    "description":this.txDescription,
				    "empName":this.seachData.empName,
				    'deptId':this.seachData.deptId,
				    "defaultAttendanceGroup":this.seachData.defaultAttendanceGroup,
//				    "countNum":this.countArr,
		      	};
		      	
				if(params.deptId<0){
					//应后台要求， 需要在选中值的时候 区分是选中的部门 还是公司，并且传入的参数值也不一样 !!!!
					params.companyId=this.findCompanyIdBydeptId(params.deptId);
				}
		      	
		      	this.common.exportByTolink('/saascloud/saas/scheduling/export/exportAll',params);
//		      	this.ApiHttp('/scheduling/export/exportAll',params,'post').then((taskId) => {
//			        this.$message({
//			          	type: "success",
//			          	message: '操作成功',
//			        });
//			        let exportObj = {
//			          	taskId,
//			          	taskName: "日程排班",
//			          	taskStatus: 0,
//			          	rootPath: "organizationPath",
//			        };
//			        //将导出任务丢入导出任务列表中
//			        //异步存储
//			        this.$store.dispatch("PushExportNotice", exportObj);
//		      	});
		    },
		    lookIsReleased(){
		    	//点击查看已发布的排班数据
		    	
		    	console.log('lookIsReleased',JSON.parse(JSON.stringify(this.seachData)))
		    	
		      	const routeData = this.$router.resolve({
			        path: '/rwork/shifts/schedule',
			        query: {
			          	htmlType: 2,
			          	...this.seachData,
//			          	yearMonth:this.seachData.month
			        }
		      	})
		      	window.open(routeData.href, '_blank')
		    },
		    haveYellowDot(row){
		    	//判断当天班次是否有黄点 待发布
		    	let isHave=false;
		    	
		    	let itDay=new Date(row.date).getTime();
		    	if(itDay<this.todayNum){
		    		//当前之前的日期  直接没有黄点
		    		return isHave;
		    	}
		    	if(this.htmlType==1){
		    		if(row.yellowDot){
		    			isHave=true;
		    		}else{
		    			
		    			if(row.shiftList){
		    				for(let it of row.shiftList){
				    			if(it.waitUp){
				    				isHave=true;
				    				break;
				    			}
				    		}
		    			}
		    			
		    		}
		    		
		    	}
		    	if(!this.needRelease&&isHave){
		    		//当前月份有需要发布的数据
		    		console.log('123132123',row)
		    		this.needRelease=true;
		    		
		    	}
		    	
		    	return isHave;
		    },
		    haveRedDot(row){
		    	//判断当天班次是否有红点 班次发生变更
		    	let isHave=false;
		    	
		    	if(row.shiftChangeMap&&row.shiftList){
		    		let t1=new Date(row.date).getTime();
		    		if(t1&&this.todayNum){
		    			isHave=t1>=this.todayNum
		    		}
		    	}
//		    	if(this.htmlType==1&&row.shiftList){
//		    		for(let it of row.shiftList){
//		    			if(it.waitUp){
//		    				isHave=true;
//		    				break;
//		    			}
//		    		}
//		    	}
		    	return isHave;
		    },
		    
		    
		    toReleaseShifts(){
		    	this.closeEvsPopover();
		    	this.showDrawer=true;
		    },
		    getWorkTimeTips(row){
		    	//获取每个班次的时间提示
		    	let tips='';
		    	if(row.workTimeArray){
		    		row.workTimeArray.map((_tt,dex2)=>{
		    			if(dex2>0){
		    				tips+='/'
		    			}
		    			if(_tt.startTime&&_tt.startTime.time){
		    				tips+=_tt.startTime.time+'-'
		    			}
		    			if(_tt.endTime&&_tt.endTime.time){
		    				tips+=_tt.startTime.time
		    			}
		    		})
		    	}
		    	return tips;
		    },
		    findCompanyIdBydeptId(depId){
		    	//通过部门ID查询出对应的公司Id;
		    	let companyId='';
		    	
		    	for(let it of this.organizArrs){
		    		if(depId==it.deptId){
		    			companyId=it.companyId;
		    		}
		    	}
		    	return companyId;
		    },
			closeEvsPopover(){
				
				document.elementFromPoint(50, 60).click();
				
			},
			closeWaitModel(){
				console.log('showDrawer=false****了~~~~~')
				this.showDrawer=false;
			},
			scrollChange(){
				
				console.log('滚动****了~~~~~')
				
				
			},

		},
	};
</script>

<style scoped lang="scss">
		.evsWarp {
		    padding: 4px 0;
			line-height: 24px;
			.it_evs {
				position: relative;
				padding: 6px 10px;
				cursor: pointer;
				i{
					margin-right: 6px;
					font-size: 16px;
				}
				.red_dot{
					position: relative;
					right: 0;
					top: -8px;
					display: inline-block;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background: red;
				}
			}
			.it_evs:hover {
				background: rgb(254, 241, 214);
			}
		}
		.addShifts {
			.it_bcs {
				cursor: pointer;
			}
			.it_bcs:hover {
				color: blue;
			}
		}
	.p_warp{
		
		.circular,.p_name{
			display: inline-block;
			vertical-align: text-top;
		}
		.circular{
			width: 36px;
			height: 36px;
			background: #1A4CEC;
			border-radius: 50%;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 36px;
			text-align: center;
			margin-right: 10px;
		}
		.p_name{
			cursor: pointer;
			max-width: 60px;
			line-height: 20px;
			.name{
				color: #2F3941;
				font-weight: 500;
			}
			.job{
				color: #999999;
				font-size: 12px;
			}
		}
		
	}
	.sitWarp {
		display: flex;
		.itSt {
			flex: 1;
			color: white;
			margin: 0;
			height: 62px;
			position: relative;
			text-align: center;
			.sfIsDfult {
				color: #666;
				line-height: 62px;
				font-size: 12px;
			}
		}
		.pointDiv {
				position: absolute;
				width: 8px;
				height: 8px;
				border-radius: 50%;
				background: #F8BA33;
				right: 0;
				top: 0;
				z-index: 9;
			}
			.pointDiv.stayUpdate {
				background: red;
			}
			.pointDiv.sfChange {
				background: orange;
			}
		
	}
	
	.el-table__cell {
		padding: 0;
		margin: 0;
	}
	.part_title{
			margin: 15px 0;
			font-size: 18px;
			font-weight: bold;
			color: #2F3941;
		}
	.conutNum{
		.ct_warp{
			width: 100%;
			overflow-x: auto;
		}
		.statis_row{
			display: flex;
			.r_total{
				line-height: 40px !important;
			}
			.t_num{
				.c_num{
					font-weight: bold;
				}
			}
			.flexdiv{
				width: 44px;
				flex-shrink:0;
				border: 1px solid #EBEEF5;
				box-sizing: border-box;
				line-height: 56px;
				text-align: center;
				color: #2F3941;
			}
			.r_name.flexdiv{
				text-align: left;
				padding-left: 44px;
				box-sizing: border-box;
				position: relative;
				width: 154px !important;
				.n_icon{
					position: absolute;
					top: 20px;
					left: 10px;
				    width: 28px;
				    height: 20px;
				    background: #1A4CEC;
				    color: #FFFFFF;
				    font-size: 12px;
				    border-radius: 4px;
				    line-height: 20px;
				    text-align: center;
				}
				.n_detail{
					width: 108px;
					box-sizing: border-box;
					padding: 8px 0;
					line-height: 20px;
					._n{
						font-weight: bold;
						color: #2F3941;
					}
					._t{
						font-size: 12px;
						color: #999999;
					}
				}
				
			}
		}
		
		.tx_c{
			/*color: #fc5d1c;*/
		}
	}
</style>
<style lang="scss">
	.mypop {
		padding: 0 !important;
		}
		.el-table.tbDate tbody tr:hover>td {
            background-color:rgba(0,0,0,0) !important;
        }
        .el-table.tbDate tbody tr:hover .colGroup{
        	background-color:#F8C85B !important;
        }
        .peoplecol .cell{
        	line-height: 62px !important;
        }
        .ck_df{
        	.el-checkbox__input.is-checked .el-checkbox__inner{
        		background-color: #1a4cec;
    				border-color: #1a4cec;
        	}
        	.el-checkbox__input.is-checked+.el-checkbox__label{
      		    color: #1a4cec;
        	}
        }
        .el-button.plainBtn{
      	    border: 1px solid #1a4cec99;
      	    color: #1A4CEC99;
        }
	.shift_date {
		.tbDate {
			td.el-table__cell,
			th.el-table__cell.is-leaf {
				border-bottom: 2px solid white;
				border-right: 2px solid white;
			}
			.d_item .cell {
				height: 62px;
				padding: 0 !important;
				margin: 0 !important;
			}
			thead th {
				.cell {
					height: 62px;
					background: #EEF6FC;
					line-height: 30px;
					text-align: center;
					color: #2F3941;
					font-weight: bold;
					.hItem {
						.i_d,
						.i_w {
							font-weight: bold;
							.todayStyle{
								background: #1A4CEC;
								color: white;
								padding: 2px 4px;
								border-radius: 3px;
							}
						}
					}
					.txRed {
						.i_w {
							color: #FC6868;
						}
					}
					.txholiday{
						color: #FC6868;
					}
				}
			}
			.el-table__cell {
				padding: 0;
				margin: 0;
			}
			.colGroup {
				color: white;
				background: #F8C85B !important;
				.cell {
					line-height: 20px;
					font-size: 12px;
					padding: 0 6px;
				}
			}
			tbody {
				text-align: center;
				.isXx.d_item {
					border-radius: 2px;
					background: repeating-linear-gradient(135deg, #e0e0e0, #e0e0e0 1px, #f5f5f5 0, #f5f5f5 8px);
				}
				.colGroup .cell{
					line-height: 14px;
					letter-spacing: 2px;
				}
				.txRed{
					
				}
				/*tr:nth-child(2n){
				.colGroup{
					background: red ;
				}
			}*/
			}
		}
		.tbDate.el-table tr {
			background-color: #F8F8F8;
		}
		.el-table--border {
			border: 1px solid #ffffff !important;
		}
		.itHover {
			height: 62px;
			cursor: pointer;
			.addIcon {
				display: none;
				font-size: 24px;
				color: #1A4CEC;
				text-align: center;
			    width: 24px;
			    height: 24px;
			    position: absolute;
			    top: 50%;
			    left: 50%;
			    margin-left: -12px;
			    margin-top: -12px;
			}
			
		}
		.itHover.actived{
			border-radius: 3px;
			border: 1px solid #1A4CEC;
			.addIcon {
				display: block !important;
			}
		}
		.itHover:hover .addIcon {
			display: block;
		}
		
	}
</style>
<style lang="scss">
	.flexDiv {
		display: flex;
		.flexIt {
			max-width: 260px;
			margin-right: 24px;
		}
	}
	.diytips{
		box-shadow: 0px 0px 12px 1px #cccccc;
		border: 1px solid #FFFFFF !important;
		.s_tipWarp{
			height: auto;
			overflow-y: auto;
			.it_tips{
				line-height: 18px;
				margin-bottom: 8px;
				.sf_name{
					font-weight: bold;
					._dot{
						display: inline-block;
						width: 8px;
						height: 8px;
						border-radius: 50%;
					}
					.tp_n{
						display: inline-block;
						width:96px;
						line-height: 14px;
						vertical-align: text-bottom;
						padding-left: 4px;
					}
					.wait_up{
						font-size: 12px;
					    color: orange;
					    background: #ffedcc;
					    padding: 0 4px;
					    border-radius: 2px;
					    position: relative;
					    top: -1px;
					}
				}
				.sf_duration,.sf_time{
					padding-left: 10px;
				}
				.sf_time{
					color: #999999;
				}
			}
		
		}
		
		
	}
</style>