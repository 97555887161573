<template>
	<div class="Y-del_shifts">
		<!--删除班次-->
	  	<div class="n_title"> 删除班次</div>
	  	<div class="list_warp">
	  		<div class="itWarp" v-for="(it,dex) in shiftListArr" :key='dex'>
		  		<div class="lineDiv checkWarp">
					<el-checkbox v-model="it.checked"></el-checkbox>
				</div>
	  			<div class="it_sts lineDiv" :style="getDiyStyle(it)"  @click.stop="it.checked=!it.checked">
	  				<div >
	  					<div class="it_name txNowrap">{{it.shiftName}} ( {{it.shiftShortName}} )</div>
	  					<div class="it_time txNowrap">
	  						<span v-for="(_tt,dex2) in it.workTimeArray">
	  							<span v-if="dex2>0">/</span>
								<span v-if="_tt.startTime&&_tt.startTime.time">{{_tt.startTime.time}}</span>-
								<span v-if="_tt.endTime&&_tt.endTime.time">{{_tt.endTime.time}}</span>
							</span>
	  					</div>
	  				</div>
	  			</div>
  			</div>
  			
  		</div>
		<div class="kq_list" v-if='activeTabs=="2"'>
			<div class="k_it" v-for="it in 5">
				<span class="k_it_icon">★</span>
				<span class="k_it_name">法定节假日</span>
			</div>
		</div>
		
		<div class="btmBtns" >
			<el-button @click="closeEvsPopover">取消</el-button>
  			<el-button type="primary" @click="saveDatas">确定</el-button>
		</div>
		
	</div>
</template>
<script>
	export default {
		props: {
			dataId: {
				type: String,
				default: '1',
			},
			datas:{
				type: Object,
				default: ()=>{
					return {
						date: "",
						shiftList:[],
						type: 1,
					}
				}
			},
			
		},
		watch:{
//			datas: {
//		      	deep: true, // 深度监听
//		      	handler(val,oval) {}
//		   	},
		},
		components: {},
		data() {
			return {
				tabsArr:[
					{name:'添加班次',val:'1'},
					{name:'修改考勤',val:'2'},
				],
				checked:false,
				activeTabs:'1',
				shiftListArr:[],
			}
		},
		
		computed: {},
		watch:{},
		created() {
			
			console.log('datas',this.datas)
			this.shiftListArr=JSON.parse(JSON.stringify(this.datas.shiftList));
			this.shiftListArr.map(it=>{
				this.$set(it, 'checked', false);
			})
			
		},
		mounted() {
			APP.$emit('countPoverXy');
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			getDiyStyle(sfs) {
				let color=sfs.shiftColor;//sfs.shiftColor;
				let bg = this.common.hexToRgba(color);
				return {
					'background': bg,
					'border-left': '3px solid ' + color
				}
			},
			async saveDatas(){
				//确定数据
				console.log('确定数据',this.datas)
				let arr=[];
				let params={
					date:this.datas.date,
					changeShiftId:this.datas.changeShiftId,
					shiftList:[],
					
				};
				this.shiftListArr.map(it=>{
					if(it.checked){
						arr.push(it.id);
						params.shiftList.push(it);
					}
				})
				if(arr.length<1){
					this.$message({type:'warning',message:'请选择需要删除的班次'});
					return;
				}
				this.$emit('delData',params.shiftList);
				console.log('delArrs',params)
//				let res = await this.ApiHttp('/scheduling/arrangeShift/checkShiftWouldDelete',params,'post');
//				console.log('del---res',res)
//				if(res){
//					this.$emit('delData',arr);
//				}
			},
			changeTbas(it){
				this.activeTabs=it.val;
				console.log('it',it)
				
			},
			closeEvsPopover(){
				
				document.elementFromPoint(50, 60).click();
				
			},
			
		},

	}
</script>

<style lang="scss" scoped>
.Y-del_shifts{
	
	
	padding: 0 20px 10px 20px;
	.n_title{
		line-height: 46px;
		font-size: 14px;
		color: #2F3941;
		box-shadow: 0px 1px 0px 0px #E4E7ED;
	}
	.tips{
		line-height: 40px;
		font-size: 12px;
	}
	
	.btmBtns{
		text-align: right;
	}
}
.tabs_warp{
	display: flex;
	.it_tap{
		padding: 10px;
		line-height: 24px;
		cursor: pointer;
		width: 50%;
		text-align: center;
		font-size: 14px;
	}
	.it_tap:hover{
		color: #409EFF;
	}
	.actived.it_tap{
		color: #1A4CEC;
		border-bottom: 2px solid #1A4CEC;
	}
}
.list_warp{
	.itWarp{
		padding: 5px 0;
		
		font-size: 12px;
		.checkWarp{
			margin-right: 12px;
			position: relative;
			top: -8px;
		    vertical-align: bottom;
			line-height: 40px;
			width: 27px;
		}
	}
	.it_sts{
		width: calc(100% - 40px);
		padding: 5px 0;
		padding-left: 12px;
		
		height: 40px;
		border-left: 2px solid;
		border-color:#1A4CEC;
		cursor: pointer;
		background: #DDE4FC;
		border-radius: 2px;
		margin: 10px 0;
		.it_name{
			color: #2F3941;
		}
		.it_time{
			line-height: 24px;
		}
	}
	
	.lineDiv{
		display: inline-block;
	}
}
.kq_list{
	.k_it{
		font-size: 12px;
		padding-left: 12px;
		height: 40px;
		line-height: 40px;
		border-left: 2px solid;
		border-color:#1A4CEC;
		cursor: pointer;
		background: #DDE4FC;
		border-radius: 2px;
		margin: 10px 0;
		.k_it_icon{
			margin-right: 10px;
		}
		.k_it_name{
			color: #2F3941;
		}
	}
	
}

</style>