<template>
	<!--更新班次-->
	<div class="Y-update_shifts">
	  	<div class="n_title"> 更新班次</div>
		<div class='itsWarp'>
			
			<div class="its" v-for="(vals,k1) in datas.shiftChangeMap" :key='k1'> 
				<div class="it_lf linediv">
					<div class="it_bc" v-for="(sfts,k2) in vals">
						<div class="it_n linediv">
							<span v-if="k2=='before'">更新前</span>
							<span v-if="k2=='now'">更新后</span>
						</div>
						<div class="it_s linediv" v-if='sfts' :style="common.shiftStyle(sfts)">
							<div class="s_name">{{sfts.shiftName}} ( {{sfts.shiftShortName}} )</div>
							<div class="s_time">
								
								<span v-for="(_tt,dex2) in sfts.workTimeArray">
									<span v-if="dex2>0">/</span>
									<span v-if="_tt.startTime">{{_tt.startTime.time}}</span>-
									<span v-if="_tt.endTime">{{_tt.endTime.time}}</span>
								</span>
								
							</div>
						</div>
						<div v-else class="it_s linediv"> 已删除</div>
						
					</div>
				</div>
				<div class="it_rt linediv">
					<el-button type="text" @click.stop='checkedUpdate(vals)' :disabled='isStartd(vals)'>更新</el-button>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
	export default {
		props: {
			dataId: {
				type: String,
				default: '1',
			},
			datas:{
				type: Object,
				default: ()=>{
					return {
						date: "",
						shiftList:[],
						type: 1,
						shiftChangeMap:{}
					}
				}
			},
			
		},
		components: {},
		data() {
			return {
				tabsArr:[
					{name:'添加班次',val:'1'},
					{name:'修改考勤',val:'2'},
				],
				
				activeTabs:'1'
			}
		},
		
		computed: {},
		watch:{},
		created() {
		},
		mounted() {
			APP.$emit('countPoverXy');
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			isStartd(vals){
				let show=false;
				//判断如果班次 已经开始的话 不允许更新
				let td=new Date();
				let nTime=td.getTime();
				let todayStr=this.common.getTimeDay(td);
				
				for(let k1 in vals){
					let _ss=vals[k1];
					if(_ss&&_ss.workTimeArray){
						for(let _ws of _ss.workTimeArray){
							let t1=new Date(this.datas.date+' '+_ws.startTime.time).getTime();
							if(_ws.startTime.workDay=='nextDay'){
								//处理次日的时候
								var dd = new Date();
								dd.setDate(dd.getDate() + 1);
								let nexDayStr=this.common.getTimeDay(dd);
								t1=new Date(nexDayStr+' '+_ws.startTime.time).getTime();
							}
							if(t1<nTime){
								//如果每个时间段的开始时间 小于当前时间 代表已经开始  
								show=true;
								break;
							}
						}
					}
					
					
					
				}
				return show;
			},
			checkedUpdate(vals){
				this.$emit('toUpadete',vals)
			},
			
			changeTbas(it){
				this.activeTabs=it.val;
				console.log('it',it)
				
			},
			closeEvsPopover(){
				
				document.elementFromPoint(50, 60).click();
				
			},
			
		},

	}
</script>

<style lang="scss" scoped>
.Y-update_shifts{
	
	
	padding: 0 20px 10px 20px;
	.n_title{
		line-height: 46px;
		font-size: 14px;
		color: #2F3941;
		box-shadow: 0px 1px 0px 0px #E4E7ED;
	}
	.linediv{
		display: inline-block;
		vertical-align: text-bottom;
	}
	.itsWarp{
		max-height: 248px;
		overflow-y: auto;
		.its{
			margin: 15px 0;
			border-radius: 4px;
			border: 1px solid #E4E7ED;
			padding: 10px 15px;
			box-sizing: border-box;
			.it_lf{
				font-size: 12px;
				.it_bc{
					margin: 5px 0;
				}
				.it_n{
					line-height: 40px;
					margin-right: 15px;
					font-size: 14px;
					color: #2F3941;
				}
				.it_s{
					width: 254px;
					height: 40px;
					background: #EEEEEE;
					padding: 5px 10px;
					border-radius: 2px;
					line-height: 16px;
					border-left:2px solid;
					border-color: #EEEEEE;
					.s_time{
						color: #888888;
					}
				}
				
			}
			.it_rt{
				margin-left: 15px;
				line-height: 96px;
				color: #1A4CEC;
				cursor: pointer;
			}
		}
		
	}
}


</style>